import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import ProfilePhoto from "../ProfilePhoto";
import PTITLE from "../Text/PTitle";
import TITLE from "../Text/Title";

const Profile = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  return (
    <Row
      style={{ marginTop: 20 }}
      className={"justify-content-center align-items-center"}
    >
      <Col
        style={{
          alignItems: "center",
          display: "flex",
        }}
        className={"justify-content-center align-items-center"}
        xs="11"
        sm="11"
        md="4"
        lg="2"
        xl="2"
      >
        <Row>
          <ProfilePhoto />
        </Row>
      </Col>
      <Col
        className={"justify-content-center align-items-center"}
        xs="11"
        sm="11"
        md="8"
        lg="9"
        xl="9"
      >
        <Row>
          <TITLE.BOLD
            style={{ marginTop: 10, marginBottom: 10 }}
            className={"justify-content-center align-items-center"}
          >
            ¡Bienvenido, {user?.firstName}!
          </TITLE.BOLD>
        </Row>
        <Row>
          <Col>
            <PTITLE.BOLD>Nombre:</PTITLE.BOLD>
            <PTITLE.REGULAR>{user?.firstName}</PTITLE.REGULAR>
          </Col>
          <Col>
            <PTITLE.BOLD>Apellido:</PTITLE.BOLD>
            <PTITLE.REGULAR>{user?.lastName}</PTITLE.REGULAR>
          </Col>
          <Col>
            <PTITLE.BOLD>Email:</PTITLE.BOLD>
            <PTITLE.REGULAR>{user?.email}</PTITLE.REGULAR>
          </Col>
          <Col>
            <PTITLE.BOLD>Telefono:</PTITLE.BOLD>
            <PTITLE.REGULAR>{user?.phone}</PTITLE.REGULAR>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Profile;
