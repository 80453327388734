import React from "react";
import styles from "./styles.module.css";

const PCAPTIONBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.PCAPTIONBOLD].join(" ")}>
    {children}
  </div>
);
const PCAPTIONSEMIBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.PCAPTIONSEMIBOLD].join(" ")}>
    {children}
  </div>
);
const PCAPTIONREGULAR = ({ style, className, children }) => (
  <div style={style} className={[className, styles.PCAPTIONREGULAR].join(" ")}>
    {children}
  </div>
);

const PCAPTION = {
  BOLD: ({ style, className, children }) =>
    PCAPTIONBOLD({ style, className, children }),
  SEMIBOLD: ({ style, className, children }) =>
    PCAPTIONSEMIBOLD({ style, className, children }),
  REGULAR: ({ style, className, children }) =>
    PCAPTIONREGULAR({ style, className, children }),
};

export default PCAPTION;
