import googleLogo from "./google.png";
import laWawaPurple from "./LawawaPurple.png";
import logo from "./Logo.png";
import logoHeader from "./logoHeader.png";
import logoPurple from "./logoPurple.png";
import modalBackground from "./modalBackground.png";
import paymentFailed from "./paymentFailed.png";
import paymentInProgress from "./paymentInProgress.png";
import paymentVerified from "./paymentVerified.png";

const images = {
  logo,
  logoHeader,
  modalBackground,
  paymentVerified,
  paymentFailed,
  paymentInProgress,
  logoPurple,
  googleLogo,
  laWawaPurple,
};

export default images;
