import React from "react";
import styles from "./styles.module.css";

const PTITLEBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.PBODYBOLD].join(" ")}>
    {children}
  </div>
);
const PTITLESEMIBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.PBODYSEMIBOLD].join(" ")}>
    {children}
  </div>
);
const PTITLEREGULAR = ({ style, className, children }) => (
  <div style={style} className={[className, styles.PBODYREGULAR].join(" ")}>
    {children}
  </div>
);

const PBODY = {
  BOLD: ({ style, className, children }) =>
    PTITLEBOLD({ style, className, children }),
  SEMIBOLD: ({ style, className, children }) =>
    PTITLESEMIBOLD({ style, className, children }),
  REGULAR: ({ style, className, children }) =>
    PTITLEREGULAR({ style, className, children }),
};

export default PBODY;
