import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import React, { useEffect, useState } from "react";
import { Ratio } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
// import CircleBottom from "../../../Components/CircleBottom";
// import CircleTop from "../../../Components/CircleTop";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import Row from "react-bootstrap/Row";
import ReactGA from "react-ga4";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import images from "../../../Assets/Images";
import Button from "../../../Components/Button";
import H2 from "../../../Components/Text/H2";
import H6 from "../../../Components/Text/H6";
import PBODY from "../../../Components/Text/Pbody";
import PTitle from "../../../Components/Text/PTitle";
import colors from "../../../Constants/colors";
import AppLayout from "../../../Containers/AppLayout";
import { companySignUpRequest, resetModal } from "../../../Store/Actions";
import styles from "./styles.module.css";

const modals = [
  {
    image: images.paymentVerified,
    title: "Registro exitoso",
    message:
      "Tu Registro ha sido exitoso, te enviaremos un correo con la información para acceder a la web app.",
  },
  {
    image: images.paymentFailed,
    title: "Ha ocurrido un error",
    message:
      "Hemos recibido tu solicitud pero ha ocurrido un error, intenta nuevamente.",
  },
  {
    image: images.paymentFailed,
    title: "Ha ocurrido un error",
    message:
      "Esta empresa o este correo ya se encuentran registrados en nuestro sistema.",
  },
];

const Register = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [modalShown, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [acceptedContract, setAcceptedContract] = useState(false);
  const companySuccess = useSelector((state) => state?.auth?.companySuccess);
  const registerModal = useSelector((state) => state?.auth?.modal);
  const authLoading = useSelector((state) => state.ui.authLoading);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/registerAdmin" });
  }, [ReactGA]);

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    watch: watch1,
    formState: { errors: errors1 },
    control: control1,
    getValues: getValues1,
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    formState: { errors: errors2 },
    control: control2,
    getValues: getValues2,
  } = useForm();

  const onSubmit = () => {
    const companyData = getValues1();
    const contactData = getValues2();
    const data = {
      companyName: companyData.companyName,
      companyPhoneNumber: companyData.companyPhoneNumber,
      companyRif: companyData.rifType + companyData.companyRif,
      companyBusinessName: companyData.businessName,
      contactEmail: contactData.contactEmail,
      contactFirstName: contactData.contactFirstName,
      contactID: contactData.contactID,
      contactLastName: contactData.contactLastName,
      contactPhoneNumber: `+`.concat(contactData.contactPhoneNumber),
    };
    dispatch(companySignUpRequest(data));
  };

  const onNext = () => {
    if (activeStep < 2) {
      setActiveStep(activeStep + 1);
    } else if (activeStep === 2) {
      onSubmit();
    }
  };

  const submitErrorHandler = (errors, e) => console.log(errors);

  useEffect(() => {
    if (registerModal !== "") {
      setModalInfo(modals[registerModal]);
      setModal(true);
    }
  }, [companySuccess]);

  useEffect(() => {
    const x = phone.replace(code, "");
    if (x.length === 10) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [phone]);

  return (
    <AppLayout navBar={false} fluid auth>
      <div className={styles.background}>
        <Container fluid>
          <Row className="justify-content-center align-items-center">
            <Col
              className="justify-content-center align-items-center"
              xs="auto"
              sm="auto"
              md="8"
              lg="6"
            >
              <Row className="justify-content-center align-items-center">
                <Image
                  style={{
                    maxHeight: 60,
                    alignSelf: "center",
                    marginBottom: 25,
                    width: "auto",
                    marginTop: 70,
                  }}
                  src={images.laWawaPurple}
                />
              </Row>
              <Row className={styles.card}>
                <H2.BOLD
                  style={{
                    textAlign: "center",
                    paddingTop: 45,
                  }}
                >
                  Registro como empresa
                </H2.BOLD>
                <Row className={"pb-5 pt-4"}>
                  <Col>
                    <Stepper
                      steps={[
                        { title: "Datos de empresa" },
                        { title: "Datos de contacto" },
                        { title: "Términos y condiciones" },
                      ]}
                      activeStep={activeStep}
                      activeColor={colors.purpleDark}
                      completeColor={colors.purpleLight}
                    />
                  </Col>
                </Row>
                {activeStep === 0 && (
                  <>
                    <form onSubmit={handleSubmit1(onNext, submitErrorHandler)}>
                      <Row className={"justify-content-center mb-4"}>
                        <Col
                          xs="12"
                          sm="12"
                          md="6"
                          lg="6"
                          className={"align-self-end mb-2"}
                        >
                          <div className={"d-flex"}>
                            <PTitle.SEMIBOLD>
                              Nombre de la empresa
                            </PTitle.SEMIBOLD>
                            <PTitle.SEMIBOLD className={styles.required}>
                              *
                            </PTitle.SEMIBOLD>
                          </div>
                          <input
                            className={styles.input}
                            type="text"
                            autoComplete="off"
                            autoCorrect="off"
                            {...register1("companyName", {
                              required: true,
                            })}
                            required
                          />
                          {errors1.companyName && (
                            <span
                              className={
                                errors1.companyName
                                  ? styles.errors
                                  : styles.errorsHidden
                              }
                            >
                              Este campo es requerido
                            </span>
                          )}
                        </Col>
                        <Col
                          xs="12"
                          sm="12"
                          md="6"
                          lg="6"
                          className={"align-self-end mb-2"}
                        >
                          <div className={"d-flex"}>
                            <PTitle.SEMIBOLD>Razón Social </PTitle.SEMIBOLD>
                            <PTitle.SEMIBOLD className={styles.required}>
                              *
                            </PTitle.SEMIBOLD>
                          </div>
                          <input
                            className={styles.input}
                            type="text"
                            autoComplete="off"
                            autoCorrect="off"
                            {...register1("businessName", {
                              required: true,
                            })}
                            required
                          />
                          {errors1.businessName && (
                            <span
                              className={
                                errors1.businessName
                                  ? styles.errors
                                  : styles.errorsHidden
                              }
                            >
                              Este campo es requerido
                            </span>
                          )}
                        </Col>
                      </Row>
                      <Row className={"justify-content-center"}>
                        <Col
                          xs="12"
                          sm="12"
                          md="6"
                          lg="6"
                          className={"align-self-end mb-2"}
                        >
                          <div className={"d-flex"}>
                            <PTitle.SEMIBOLD>RIF</PTitle.SEMIBOLD>
                            <PTitle.SEMIBOLD className={styles.required}>
                              *
                            </PTitle.SEMIBOLD>
                          </div>
                          <Row className={"justify-content-center"}>
                            <Col
                              xs="auto"
                              sm="auto"
                              md="4"
                              lg="4"
                              className={"align-self-center"}
                            >
                              <select
                                {...register1("rifType", {
                                  required: true,
                                })}
                                defaultValue={"J-"}
                                className={styles.input}
                              >
                                <option value="J-">J-</option>
                                <option value="V-">V-</option>
                                <option value="G-">G-</option>
                                <option value="E-">E-</option>
                                <option value="P-">P-</option>
                              </select>
                            </Col>
                            <Col className={"align-self-center ps-0"}>
                              <input
                                className={styles.input}
                                type="number"
                                autoComplete="off"
                                autoCorrect="off"
                                {...register1("companyRif", {
                                  required: true,
                                })}
                                required
                                maxLength={9}
                              />
                            </Col>
                          </Row>

                          {errors1.companyRif && (
                            <span
                              className={
                                errors1.companyRif
                                  ? styles.errors
                                  : styles.errorsHidden
                              }
                            >
                              Este campo es requerido
                            </span>
                          )}
                        </Col>
                        <Col
                          xs="12"
                          sm="12"
                          md="6"
                          lg="6"
                          className={"align-self-end mb-2"}
                        >
                          <div className={"d-flex"}>
                            <PTitle.SEMIBOLD>Teléfono </PTitle.SEMIBOLD>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            autoComplete="off"
                            autoCorrect="off"
                            {...register1("companyPhoneNumber", {
                              required: false,
                            })}
                          />
                        </Col>
                      </Row>
                      <Row className={"justify-content-center"}>
                        <Col xs="6" sm="6" md="5" lg="5">
                          <input
                            className={styles.button}
                            value={"Siguiente"}
                            type="submit"
                          />
                        </Col>
                      </Row>
                    </form>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <form onSubmit={handleSubmit2(onNext, submitErrorHandler)}>
                      <Row className={"justify-content-center mb-2"}>
                        <Col
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          className={"align-self end"}
                        >
                          <div className={"d-flex"}>
                            <PTitle.SEMIBOLD>Nombre</PTitle.SEMIBOLD>
                            <PTitle.SEMIBOLD className={styles.required}>
                              *
                            </PTitle.SEMIBOLD>
                          </div>
                          <input
                            className={styles.input}
                            type="text"
                            autoComplete="off"
                            autoCorrect="off"
                            {...register2("contactFirstName", {
                              required: true,
                              maxLength: 80,
                            })}
                            required
                          />
                          <span
                            className={
                              errors2.contactFirstName
                                ? styles.errors
                                : styles.errorsHidden
                            }
                          >
                            Este campo es requerido
                          </span>
                        </Col>
                        <Col
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          className={"align-self-end"}
                        >
                          <div className={"d-flex"}>
                            <PTitle.SEMIBOLD>Apellido</PTitle.SEMIBOLD>
                            <PTitle.SEMIBOLD className={styles.required}>
                              *
                            </PTitle.SEMIBOLD>
                          </div>
                          <input
                            className={styles.input}
                            type="text"
                            autoComplete="off"
                            autoCorrect="off"
                            {...register2("contactLastName", {
                              required: true,
                              maxLength: 100,
                            })}
                            required
                          />
                          <span
                            className={
                              errors2.contactLastName
                                ? styles.errors
                                : styles.errorsHidden
                            }
                          >
                            Este campo es requerido
                          </span>
                        </Col>
                      </Row>
                      <Row className={"justify-content-center"}>
                        <Col
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          className={"align-self-end"}
                        >
                          <div className={"d-flex"}>
                            <PTitle.SEMIBOLD>
                              Correo electrónico{" "}
                            </PTitle.SEMIBOLD>
                            <PTitle.SEMIBOLD className={styles.required}>
                              *
                            </PTitle.SEMIBOLD>
                          </div>
                          <input
                            className={styles.input}
                            type="email"
                            autoComplete="off"
                            autoCorrect="off"
                            {...register2("contactEmail", {
                              required: true,
                              // pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            required
                          />
                          <span
                            className={
                              errors2.contactEmail
                                ? styles.errors
                                : styles.errorsHidden
                            }
                          >
                            Este campo es requerido
                          </span>
                        </Col>
                        <Col
                          xs="12"
                          sm="6"
                          md="6"
                          lg="6"
                          className={"align-self-end"}
                        >
                          <div className={"d-flex"}>
                            <PTitle.SEMIBOLD>Número de cédula </PTitle.SEMIBOLD>
                            <PTitle.SEMIBOLD className={styles.required}>
                              *
                            </PTitle.SEMIBOLD>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            autoComplete="off"
                            autoCorrect="off"
                            {...register2("contactID", {
                              required: true,
                            })}
                            required
                          />
                          <span
                            className={
                              errors2.contactID
                                ? styles.errors
                                : styles.errorsHidden
                            }
                          >
                            Este campo es requerido
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" sm="12" md="6" lg="6">
                          <div className={"d-flex"}>
                            <PTitle.SEMIBOLD>Teléfono celular </PTitle.SEMIBOLD>
                            <PTitle.SEMIBOLD className={styles.required}>
                              *
                            </PTitle.SEMIBOLD>
                          </div>
                          <Controller
                            name="contactPhoneNumber"
                            control={control2}
                            defaultValue={false}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <PhoneInput
                                country={"ve"}
                                value={phone}
                                onChange={onChange}
                                preferredCountries={["ve"]}
                                enableSearch
                                localization={es}
                                searchPlaceholder={"Buscar"}
                                searchNotFound={"País no encontrado"}
                                autocompleteSearch
                                defaultErrorMessage={"Número no válido"}
                                masks={{ ve: "(...) ...-...." }}
                                countryCodeEditable={false}
                                inputClass={styles.test}
                                containerStyle={{
                                  display: "flex",
                                  height: 50,
                                  width: "100%",
                                }}
                                buttonStyle={{
                                  backgroundColor: "white",
                                  height: "100%",
                                  borderRadius: 5,
                                  width: 60,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                inputStyle={{
                                  borderWidth: 1,
                                  borderRadius: 5,
                                  textAlign: "left",
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  height: 50,
                                  boderColor: "red",
                                  marginLeft: 70,
                                  width: "100%",
                                  fontFamily: "Montserrat",
                                  fontSize: "1rem",
                                  color: "black",
                                }}
                              />
                            )}
                          />
                          <span
                            className={
                              errors2.contactPhoneNumber
                                ? styles.errors
                                : styles.errorsHidden
                            }
                          >
                            Este campo es requerido
                          </span>
                        </Col>
                      </Row>
                      <Row className={"justify-content-center"}>
                        <Col
                          xs="6"
                          sm="6"
                          md="5"
                          lg="5"
                          className={"align-self-end"}
                        >
                          <Button
                            className={styles.button}
                            title={"Atrás"}
                            onPress={() => setActiveStep(activeStep - 1)}
                          />
                        </Col>
                        <Col
                          xs="6"
                          sm="6"
                          md="5"
                          lg="5"
                          className={"align-self-end"}
                        >
                          <input
                            className={styles.button}
                            value={"Siguiente"}
                            type="submit"
                          />
                        </Col>
                      </Row>
                    </form>
                  </>
                )}
                {activeStep === 2 && (
                  <>
                    <Row className={"justify-content-center"}>
                      <div className={styles.iframeContainer}>
                        <Ratio aspectRatio="4x3">
                          <iframe src="https://www.enlawawa.com/terminos-y-condiciones" />
                        </Ratio>
                      </div>
                    </Row>
                    <Row className={"justify-content-center mt-3"}>
                      <Col
                        xs="6"
                        sm="6"
                        md="8"
                        lg="8"
                        className={"align-items-center text-center"}
                      >
                        <input
                          name="acceptedContract"
                          type="checkbox"
                          checked={acceptedContract}
                          onChange={() =>
                            setAcceptedContract(!acceptedContract)
                          }
                        />
                        <span className={"ms-2"}>
                          Acepto los términos y condiciones
                        </span>
                      </Col>
                    </Row>
                    <Row className={"justify-content-center mt-3"}>
                      <Col
                        xs="6"
                        sm="6"
                        md="5"
                        lg="5"
                        className={"align-self-end"}
                      >
                        <Button
                          className={styles.button}
                          title={"Atrás"}
                          onPress={() => setActiveStep(activeStep - 1)}
                        />
                      </Col>

                      <Col
                        xs="6"
                        sm="6"
                        md="5"
                        lg="5"
                        className={"align-self-end"}
                      >
                        <Button
                          className={styles.button}
                          title={"Registrarse"}
                          onPress={() => onSubmit()}
                          disabled={!acceptedContract}
                          style={
                            !acceptedContract ? { pointerEvents: "none" } : null
                          }
                          loading={authLoading}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <div className={styles.question}>
                  ¿Ya tienes una cuenta?{" "}
                  <Link className={styles.register} to="/">
                    <span>Ingresa aqui</span>
                  </Link>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        fullscreen={true}
        animation={true}
        centered={true}
        show={modalShown}
      >
        <ModalBody className={styles.modal} style={{}}>
          <Image style={{ width: 250 }} src={images.logoPurple} />
          <Image
            style={{
              width: 100,
              marginBottom: 15,
              marginTop: 80,
            }}
            src={modalInfo?.image}
          />
          <H6.BOLD style={{ marginTop: 15, marginBottom: 10 }}>
            {modalInfo?.title}
          </H6.BOLD>
          <PBODY.BOLD
            style={{
              fontSize: 16,
              maxWidth: 400,
              textAlign: "center",
              color: "#1A0063",
            }}
          >
            {modalInfo.message}
          </PBODY.BOLD>
          <Button
            onPress={() => {
              setModal(false);
              history.replace("/");
              dispatch(resetModal());
            }}
            style={{ width: 400, marginTop: 60 }}
            title={"Cerrar"}
          />
        </ModalBody>
      </Modal>
    </AppLayout>
  );
};

export default Register;
