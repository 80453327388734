import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import admin from "./Reducers/admin";
import auth from "./Reducers/auth";
import payments from "./Reducers/payments";
import plans from "./Reducers/plans";
import ui from "./Reducers/ui";
// import config from "./Reducers/config";

// const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const rootReducer = combineReducers({
  auth,
  ui,
  plans,
  payments,
  admin,
});

const store = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
};

export default store;
