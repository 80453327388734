import React from "react";
import styles from "./styles.module.css";

const H5BOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H5BOLD].join(" ")}>
    {children}
  </div>
);
const H5SEMIBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H5SEMIBOLD].join(" ")}>
    {children}
  </div>
);
const H5REGULAR = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H5REGULAR].join(" ")}>
    {children}
  </div>
);

const H5 = {
  BOLD: ({ style, className, children }) =>
    H5BOLD({ style, className, children }),
  SEMIBOLD: ({ style, className, children }) =>
    H5SEMIBOLD({ style, className, children }),
  REGULAR: ({ style, className, children }) =>
    H5REGULAR({ style, className, children }),
};

export default H5;
