import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { ReactComponent as Logodown } from "../../../Assets/SVG/downArrow.svg";
import { ReactComponent as Logo } from "../../../Assets/SVG/upArrow.svg";
import Profile from "../../../Components/Profile";
import H1 from "../../../Components/Text/H1";
import H6 from "../../../Components/Text/H6";
import {
  default as Colors,
  default as colors,
} from "../../../Constants/colors";
import AppLayout from "../../../Containers/AppLayout";
import styles from "./styles.module.css";

const Home = (props) => {
  const user = useSelector((state) => state.auth.user);

  return (
    <AppLayout>
      <Profile />
      <Row
        style={{ marginTop: 40 }}
        className={"justify-content-center align-items-center"}
      >
        <Col
          className={"justify-content-center align-items-center"}
          xs="10"
          sm="10"
          lg="5"
          md="10"
        >
          <br></br>
          <H6.REGULAR>
            Fuiste añadido a nuestra lista de espera para que te montes en la
            Wawa.
          </H6.REGULAR>
          <br></br>
          <H6.REGULAR>
            Actualmente tienes{" "}
            <span style={{ color: colors.purpleDark, fontWeight: "700" }}>
              {user?.referredUsers?.length} personas referidas
            </span>
            , sigue compartiendo el app para subir de posición.
          </H6.REGULAR>
        </Col>
        <Col
          style={{
            borderWidth: 1,
            borderColor: "#BBBBBB",
            borderStyle: "solid",
            paddingTop: 35,
            paddingBottom: 35,
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 20,
          }}
          xs="10"
          sm="10"
          lg="6"
          md="10"
        >
          <Row className={"justify-content-center align-items-center"}>
            <Col
              className={[
                "justify-content-center align-items-center",
                styles.border,
              ]}
              xs="10"
              sm="10"
              lg="6"
              md="6"
              style={{
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <Row
                className={
                  "justify-content-center align-items-center align-self-center"
                }
              >
                <Col xs="auto" lg="auto" md="auto" sm="auto">
                  <Row
                    className={
                      "justify-content-center align-items-center align-self-center"
                    }
                    xs="auto"
                    lg="auto"
                    md="auto"
                    sm="auto"
                  >
                    <Logo />
                    <H1.BOLD style={{ color: Colors.green, padding: 0 }}>
                      {user.waitlistPosition}
                    </H1.BOLD>
                  </Row>
                </Col>
                <Col xs="auto" lg="auto" md="auto" sm="auto">
                  <H6.REGULAR>
                    personas están<br></br>delante de ti.
                  </H6.REGULAR>
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                paddingTop: 10,
                paddingBottom: 10,
              }}
              className={"justify-content-center align-items-center"}
              xs="10"
              sm="10"
              lg="6"
              md="6"
            >
              <Row
                className={
                  "justify-content-center align-items-center align-self-center"
                }
              >
                <Col xs="auto" lg="auto" md="auto" sm="auto">
                  <Row
                    className={
                      "justify-content-center align-items-center align-self-center"
                    }
                    xs="auto"
                    lg="auto"
                    md="auto"
                    sm="auto"
                  >
                    <Logodown />
                    <H1.BOLD style={{ color: Colors.red, padding: 0 }}>
                      {user.nextInQueue}
                    </H1.BOLD>
                  </Row>
                </Col>
                <Col xs="auto" lg="auto" md="auto" sm="auto">
                  <H6.REGULAR>
                    personas están<br></br>detrás de ti.
                  </H6.REGULAR>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </AppLayout>
  );
};

export default Home;
