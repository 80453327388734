import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Controller, useForm } from "react-hook-form";
import { IoCloudUploadOutline } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../Components/Button";
import PTitle from "../../../Components/Text/PTitle";
import { createUser, uploadCompanyUsers } from "../../../Store/Actions";
import styles from "./styles.module.css";

const AdminAddUsers = (props) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [pin, setPin] = useState("");
  const [sendCode, setSendCode] = useState(false);
  const codeInputLogin = useSelector((state) => state.ui.codeInputLogin);
  const authLoading = useSelector((state) => state.ui.authLoading);
  const [file, setFile] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    getValues,
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    control: control2,
    getValues: getValues2,
  } = useForm();

  const onSubmit = (data, e) => {
    const x = { ...data };
    x.phone = `+${data.phone}`;
    console.log(x);
    dispatch(createUser(x));
  };

  const submitErrorHandler = (errors, e) => console.log(errors);

  const submitCSV = (data, e) => {
    var bodyFormData = new FormData();
    bodyFormData.append("employees", file);
    dispatch(uploadCompanyUsers(bodyFormData));
  };

  const submitErrorHandlerCSV = (errors, e) => console.log(errors);

  useEffect(() => {
    const x = phone.replace(code, "");
    if (x.length === 10) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [phone]);

  const onUpload = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit, submitErrorHandler)}>
        <Row className={"justify-content-center"}>
          <Col xs="10" sm="10" md="6" lg="6">
            <div className={"d-flex"}>
              <PTitle.SEMIBOLD>Nombre </PTitle.SEMIBOLD>
              <PTitle.SEMIBOLD className={styles.required}>*</PTitle.SEMIBOLD>
            </div>
            <input
              className={styles.input}
              type="text"
              autoComplete="off"
              autoCorrect="off"
              {...register("firstName", {
                required: true,
                maxLength: 80,
              })}
              required
            />
            <span
              className={errors.firstName ? styles.errors : styles.errorsHidden}
            >
              Este campo es requerido
            </span>
          </Col>
          <Col xs="10" sm="10" md="6" lg="6">
            <div className={"d-flex"}>
              <PTitle.SEMIBOLD>Apellido </PTitle.SEMIBOLD>
              <PTitle.SEMIBOLD className={styles.required}>*</PTitle.SEMIBOLD>
            </div>
            <input
              className={styles.input}
              type="text"
              autoComplete="off"
              autoCorrect="off"
              {...register("lastName", {
                required: true,
                maxLength: 100,
              })}
              required
            />
            <span
              className={errors.lastName ? styles.errors : styles.errorsHidden}
            >
              Este campo es requerido
            </span>
          </Col>
        </Row>
        <Row className={"justify-content-center"}>
          <Col xs="10" sm="10" md="6" lg="6">
            <div className={"d-flex"}>
              <PTitle.SEMIBOLD>Número de cédula </PTitle.SEMIBOLD>
              <PTitle.SEMIBOLD className={styles.required}>*</PTitle.SEMIBOLD>
            </div>
            <input
              className={styles.input}
              type="number"
              autoComplete="off"
              autoCorrect="off"
              {...register("documentID", {
                required: true,
              })}
              required
            />
            <span
              className={
                errors.documentID ? styles.errors : styles.errorsHidden
              }
            >
              Este campo es requerido
            </span>
          </Col>
          <Col xs="10" sm="10" md="6" lg="6">
            <div className={"d-flex"}>
              <PTitle.SEMIBOLD>Correo electrónico </PTitle.SEMIBOLD>
              <PTitle.SEMIBOLD className={styles.required}>*</PTitle.SEMIBOLD>
            </div>

            <input
              className={styles.input}
              type="email"
              autoComplete="off"
              autoCorrect="off"
              {...register("email", {
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              required
            />
            <span
              className={errors.email ? styles.errors : styles.errorsHidden}
            >
              Este campo es requerido
            </span>
          </Col>
        </Row>
        <Row className={"justify-content-center"}>
          <Col xs="10" sm="10" md="6" lg="6">
            <div className={"d-flex"}>
              <PTitle.SEMIBOLD>Teléfono </PTitle.SEMIBOLD>
              <PTitle.SEMIBOLD className={styles.required}>*</PTitle.SEMIBOLD>
            </div>
            <Controller
              name="phone"
              control={control}
              defaultValue={false}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  country={"ve"}
                  value={phone}
                  onChange={onChange}
                  preferredCountries={["ve"]}
                  enableSearch
                  localization={es}
                  searchPlaceholder={"Buscar"}
                  searchNotFound={"País no encontrado"}
                  autocompleteSearch
                  defaultErrorMessage={"Número no válido"}
                  masks={{ ve: "(...) ...-...." }}
                  countryCodeEditable={false}
                  inputClass={styles.test}
                  containerStyle={styles.test}
                  containerStyle={{
                    display: "flex",
                    height: 43,
                    width: "100%",
                  }}
                  buttonStyle={{
                    backgroundColor: "white",
                    height: "100%",
                    borderRadius: 5,
                    width: 60,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  inputStyle={{
                    borderWidth: 1,
                    borderRadius: 5,
                    textAlign: "left",
                    paddingLeft: 10,
                    paddingRight: 10,
                    height: 43,
                    boderColor: "red",
                    marginLeft: 70,
                    width: "100%",
                    fontFamily: "Montserrat",
                    fontSize: "1rem",
                    color: "black",
                  }}
                />
              )}
            />
            <span
              className={
                errors.phoneNumber ? styles.errors : styles.errorsHidden
              }
            >
              Este campo es requerido
            </span>
          </Col>
          <Col xs="12" sm="12" md="6" lg="6">
            <Button
              variant={"primary"}
              title={"Crear Usuario"}
              // loading={authLoading}
              form
              style={{
                width: "100%",
                marginTop: 23,
                marginBottom: 10,
                border: "none",
                height: 43,
              }}
            />
          </Col>
        </Row>
      </form>
      <div className={styles.or}>
        <div className={styles.center}>
          <div className={styles.separator} />
          <div>o</div>
          <div className={styles.separator} />
        </div>
      </div>
      <form onSubmit={handleSubmit2(submitCSV, submitErrorHandlerCSV)}>
        <Row className={"justify-content-center"}>
          <span style={{ textAlign: "center", marginBottom: 15, marginTop: 5 }}>
            SUBIR POR CSV -{" "}
            <a href="https://storage.googleapis.com/lawawa-files/Formato%20Carga%20de%20Empleados.xlsx">
              Descargar plantilla
            </a>
          </span>
          <div className={styles.csvInput}>
            <label className={styles.customFile}>
              <input
                {...register2("csvFile", {
                  required: true,
                })}
                type="file"
                onChange={onUpload}
              />
              <IoCloudUploadOutline style={{ marginRight: 10 }} size={25} />
              Subir archivo
            </label>
          </div>
          <div className={styles.filepreview}>{file?.name}</div>
          <Col sm="6" md="6" lg="6" xl="6">
            <input
              className={styles.button}
              value={"Siguiente"}
              type="submit"
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default AdminAddUsers;
