import { FUNDING, PayPalButtons } from "@paypal/react-paypal-js";
import {
  closeUbii as closeUbiiDev,
  initUbii as initUbiiDev,
} from "@ubiipagos/boton-ubii-dc";
import { closeUbii, initUbii } from "@ubiipagos/boton-ubii";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import Row from "react-bootstrap/Row";
import CurrencyInput from "react-currency-input-field";
import { useForm } from "react-hook-form";
import { IoAdd, IoInformationCircleSharp, IoRemove } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import images from "../../../Assets/Images";
import Button from "../../../Components/Button";
import H3 from "../../../Components/Text/H3";
import H6 from "../../../Components/Text/H6";
import PBODY from "../../../Components/Text/Pbody";
import AppLayout from "../../../Containers/AppLayout";
import { sendPayPalPayment, sendUbiiPayment } from "../../../Store/Actions";
import { startPaymentSaving } from "../../../Store/Actions/ui";
import styles from "./styles.module.css";

const modals = [
  {
    image: images.paymentVerified,
    title: "Pago Exitoso",
    message:
      "Tu pago fue realizado exitosamente, ya puedes usar el saldo para asignar los pases a tus usuarios.",
  },
  {
    image: images.paymentInProgress,
    title: "Su pago se encuentra en verificación",
    message:
      "En lo que validemos tu pago, recibirás una notificación y podrás disfrutar de La Wawa.",
  },
  {
    image: images.paymentFailed,
    title: "Pago rechazado",
    message:
      "Tu pago fue rechazado ya que no pudimos validarlo. Comunícate con nosotros a través de nuestro formulario de contacto",
  },
];

const CompanyCart = (props) => {
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.plans.plans);
  const user = useSelector((state) => state.auth.user);
  const [modalShown, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [promoCode, setPromoCode] = useState(false);
  const [promoCodeVal, setPromoCodeVal] = useState("");
  const [promoValid, setPromoValid] = useState(null);
  const [focusedInput, setFocusedInput] = useState(false);
  const [totalAfterDiscount, setTotalAfterDiscount] = useState(0);
  const [totalAfterDiscountInBS, setTotalAfterDiscountInBS] = useState(0);
  const [totalInBS, setTotalInBS] = useState(0);
  const [orderId, setOrderId] = useState(
    Math.floor(Math.random() * (1000000 - 0 + 1)) + 0
  );

  const [cartPlans, setCartPlans] = useState(
    plans
      .map((plan) => ({ ...plan, quantity: 0, total: 0, totalBS: 0 }))
      .sort((a, b) => {
        if (a.type < b.type) return -1;
        if (a.type > b.type) return 1;
        return 0;
      })
  );

  const { register, handleSubmit } = useForm();

  const callbackFn = (answer) => {
    console.log(answer);
    if (answer.error) {
      //Aquí va su lógica para manejar errores. Ejm:
      console.log("el error fue:");
      console.log(answer.data);
      setModalInfo(modals[2]);
      setModal(true);
    } else {
      if (answer.data.R === "0") {
        setModalInfo(modals[0]);
        setModal(true);
        submitPagoUbii(answer.data.orderID);
      } else {
        setModalInfo(modals[2]);
        setModal(true);
      }
    }
  };

  const submitPagoUbii = (orderID) => {
    const data = {
      orderID,
      companyID: user.company._id,
      amount: totalAfterDiscount,
    };
    dispatch(startPaymentSaving());
    dispatch(sendUbiiPayment(data));
  };

  const submitPagoPaypal = (orderID) => {
    const data = {
      orderID,
      companyID: user.company._id,
      amount: totalAfterDiscount,
    };
    dispatch(startPaymentSaving());
    dispatch(sendPayPalPayment(data));
  };

  const onSubmit = (data, e) => {
    setPromoCode(true);
    if (data.promoCode === "12345") {
      setPromoValid(true);
      setTotalAfterDiscount(total - total * 0.1);
      setTotalAfterDiscountInBS(totalInBS - totalInBS * 0.1);
    } else if (data.promoCode !== "12345") {
      setPromoValid(false);
      setTotalAfterDiscount(total);
      setTotalAfterDiscountInBS(totalInBS);
    }
  };

  const submitErrorHandler = (errors, e) => console.log(errors);

  const updatePlan = (amount, index, type, inputValue) => {
    const X = [...cartPlans];
    if (type === "add") {
      X[index].quantity += amount;
      X[index].total = (X[index].quantity * X[index].cost)?.toFixed(2);
      X[index].totalBS = (X[index].quantity * X[index].costBS)?.toFixed(2);
    } else if (type === "remove") {
      if (X[index].quantity > 0) {
        X[index].quantity += amount;
        X[index].total = (X[index].quantity * X[index].cost)?.toFixed(2);
        X[index].totalBS = (X[index].quantity * X[index].costBS)?.toFixed(2);
      }
    } else {
      if (inputValue <= 99) {
        X[index].quantity = inputValue;
        X[index].total = (X[index].quantity * X[index].cost)?.toFixed(2);
        X[index].totalBS = (X[index].quantity * X[index].costBS)?.toFixed(2);
      }
      if (isNaN(inputValue)) {
        X[index].quantity = 0;
        X[index].total = 0;
        X[index].totalBS = 0;
      }
    }
    setCartPlans(X);
  };

  const closeModal = () => {
    setModal(false);
    history.replace("/");
  };

  const columns = [
    {
      dataField: "_id",
      text: "Id",
      style: {
        border: "none",
      },
      type: "string",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Nombre",
      style: {
        border: "none",
      },
      type: "date",
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            {cell}
            <OverlayTrigger
              key={rowIndex}
              trigger="hover"
              placement={"top-start"}
              overlay={
                <Tooltip id={`tooltip-right}`}>{row.description}</Tooltip>
              }
            >
              <div style={{ display: "inline-block" }}>
                <IoInformationCircleSharp
                  style={{ marginLeft: 10 }}
                  size={20}
                />
              </div>
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      dataField: "type",
      text: "Tipo de plan",
      style: {
        border: "none",
      },
      type: "string",
      formatter: (cell, row) => {
        return <div>{cell === "rides" ? "Viajes" : "Suscripción"}</div>;
      },
    },
    {
      dataField: "cost",
      text: "Precio",
      style: {
        border: "none",
      },
      formatter: (cell) => {
        return <div>${cell}</div>;
      },
      type: "string",
    },
    {
      dataField: "quantity",
      text: "Cantidad",
      style: {
        border: "none",
      },
      type: "string",
      formatter: (cell, row, rowIndex) => {
        return (
          <div className={styles.icon}>
            <IoRemove
              size={20}
              className={styles.iconNew}
              onClick={() => updatePlan(-1, rowIndex, "remove")}
            />
            <input
              onChange={(e) => {
                updatePlan(0, rowIndex, "update", parseInt(e.target.value, 10));
              }}
              min={0}
              max={99}
              pattern="[0-99]*"
              className={styles.quantity}
              type="number"
              autoComplete="off"
              autoCorrect="off"
              maxLength={2}
              value={row.quantity}
            />
            <IoAdd
              size={20}
              className={styles.iconNew}
              onClick={() => updatePlan(1, rowIndex, "add")}
            />
          </div>
        );
      },
    },
    {
      dataField: "total",
      text: "Total",
      style: {
        border: "none",
      },
      type: "number",
      formatter: (cell, row) => {
        return (
          <div>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(row?.total)}
          </div>
        );
      },
      headerStyle: { minWidth: "80px" },
    },
  ];

  useEffect(() => {
    let USD = 0;
    let BS = 0;
    cartPlans.map((plan) => {
      USD += plan.cost * plan.quantity;
      BS += plan.costBS * plan.quantity;
    });
    setTotal(USD);
    setTotalInBS(BS);
  }, [cartPlans]);

  useEffect(() => {
    if (promoCode === true) {
      setTotalAfterDiscount(total - total * 0.1);
      setTotalAfterDiscountInBS(totalInBS - totalInBS * 0.1);
    } else if (promoCode === false) {
      setTotalAfterDiscount(total);
      setTotalAfterDiscountInBS(totalInBS);
    }
  }, [total]);

  useEffect(() => {
    if (totalAfterDiscount > 1) {
      if (process.env.NODE_ENV === "development") {
        initUbiiDev(
          "ubiiboton",
          {
            amount_ds: totalAfterDiscount,
            amount_bs: totalAfterDiscountInBS,
            concept: "Pago de viaje",
            principal: "bs",
            clientId: process.env.REACT_APP_UBII_CLIENT_ID,
            orderId: orderId.toString(),
          },
          callbackFn,
          {
            text: "Pagar",
          }
        );
      } else {
        initUbii(
          "ubiiboton",
          {
            amount_ds: totalAfterDiscount,
            amount_bs: totalAfterDiscountInBS,
            concept: "Pago de viaje",
            principal: "bs",
            clientId: process.env.REACT_APP_UBII_CLIENT_ID,
            orderId: orderId.toString(),
          },
          callbackFn,
          {
            text: "Pagar",
          }
        );
      }
    }
  }, [totalAfterDiscount]);

  return (
    <AppLayout sideBar>
      <div>
        <Row
          style={{
            marginTop: "2vh",
            userSelect: "none",
          }}
        >
          <H3.BOLD>Calcula cuanto saldo</H3.BOLD>
        </Row>
        <Row>
          <H3.BOLD>debes añadirle a tu cuenta</H3.BOLD>
        </Row>
        <Row
          style={{
            paddingTop: "2vh",
          }}
        >
          <Col sm="12" md="12" lg="12" xl="8">
            <ToolkitProvider keyField="_id" data={cartPlans} columns={columns}>
              {(props) => (
                <div className={styles.test}>
                  <BootstrapTable
                    remote
                    {...props.baseProps}
                    keyField={"_id"}
                    hover
                    bordered={false}
                    condensed
                    // sort={{
                    //   order: "desc",
                    //   dataField: "date",
                    // }}
                    // rowStyle={{ paddingTop: 20, paddingBottom: 20 }}
                    rowClasses={styles.tableRow}
                    headerClasses={styles.headerTable}
                    classes={`${styles.prueba}`}
                    wrapperClasses="table-responsive"
                  />
                </div>
              )}
            </ToolkitProvider>
          </Col>
          <Col sm="12" md="12" lg="12" xl="4">
            <div className={styles.cart2}>
              <H6.BOLD>
                Ingresa la cantidad específica que desees agregar a tu cuenta
              </H6.BOLD>
              <CurrencyInput
                id="input-example"
                name="input-name"
                placeholder="Saldo"
                defaultValue={0}
                decimalsLimit={2}
                className={styles.input}
                prefix={"$"}
                value={total.toFixed(2)}
                onValueChange={(value, name) => {
                  let numValue = parseFloat(value);
                  console.log(numValue);
                  setTotal(numValue);
                }}
              />
              {/* <div>
                <div>
                  <div className={styles.summaryRow}>
                    <PBODY.SEMIBOLD>
                      ¿Tienes un cupón de descuento?
                    </PBODY.SEMIBOLD>
                    <form
                      onSubmit={handleSubmit(onSubmit, submitErrorHandler)}
                      className={styles.form}
                    >
                      <input
                        {...register("promoCode", {})}
                        className={styles.discountInput}
                        placeholder={"Código de descuento"}
                        value={promoCodeVal}
                        onChange={(e) => setPromoCodeVal(e.target.value)}
                        autoCapitalize="none"
                        autoCorrect="off"
                        autoComplete="off"
                        aria-autocomplete="none"
                        onFocus={() => setFocusedInput(true)}
                        onBlur={() => setFocusedInput(false)}
                      />
                      <button
                        disabled={promoCode}
                        className={styles.button}
                        type="submit"
                      >
                        Aplicar
                      </button>
                    </form>
                  </div>
                </div>
                <div
                  className={
                    promoCode
                      ? promoValid
                        ? styles.discountCorrect
                        : styles.discountIncorrect
                      : styles.hidden
                  }
                >
                  <div className={styles.promo}>
                    {promoValid && (
                      <IoCheckmarkSharp style={{ marginRight: 10 }} />
                    )}
                    {!promoValid && (
                      <IoCloseSharp style={{ marginRight: 10 }} />
                    )}
                    <span style={{ fontSize: "0.8rem", marginRight: 5 }}>
                      Código:
                    </span>
                    {promoCodeVal}
                  </div>
                  <div className={styles.discount}>-20%</div>
                </div>
                <div
                  onClick={() => {
                    setPromoCode(null);
                    setTotalAfterDiscount(total);
                  }}
                  className={
                    promoCode ? styles.deleteDiscount : styles.hiddenDiscount
                  }
                >
                  Eliminar descuento
                </div>
                <Spinner variant={"primary"} size={"md"} animation="border" />
              </div> */}
              <div className={styles.summaryTotal}>
                <div>TOTAL:</div>
                <div>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(totalAfterDiscount)}
                </div>
              </div>
              {/* <Button
                style={{ marginTop: 10 }}
                disabled={total < 1}
                title={"Agregar saldo"}
                onPress={() => setPayment(true)}
              /> */}
              <div className={styles.paymentWrapper}>
                <H6.BOLD
                  style={{
                    color: "black",
                    textAlign: "left",
                    marginTop: 10,
                  }}
                >
                  Métodos de pago
                </H6.BOLD>
                <div className={styles.row}>
                  <div className={styles.ubiiWrapper}>
                    <PBODY.REGULAR style={{ textAlign: "left" }}>
                      Paga mediante Pago Móvil, Tarjeta de Débito en bolívares y
                      Zelle a través de{" "}
                      <span style={{ color: "#9570fc", fontWeight: "bold" }}>
                        UbiiPagos
                      </span>
                      :
                    </PBODY.REGULAR>
                    <div style={{ marginTop: 25 }}>
                      {totalAfterDiscount < 1 && (
                        <div className={styles.fakebutton}>Pagar</div>
                      )}
                      {totalAfterDiscount > 1 && (
                        <div>
                          <button id={"ubiiboton"}></button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.separator}>
                    <div className={styles.line}></div>
                    <PBODY.REGULAR className={styles.separatorText}>
                      o paga utilizando
                    </PBODY.REGULAR>
                    <div className={styles.line}></div>
                  </div>
                  <div className={styles.paypalWrapper}>
                    <PayPalButtons
                      className={styles.buttonPayment}
                      style={{ shape: "pill" }}
                      disabled={totalAfterDiscount < 1}
                      fundingSource={FUNDING.PAYPAL}
                      forceReRender={totalAfterDiscount}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then(function (details) {
                          setModalInfo(modals[0]);
                          submitPagoPaypal(details.id);
                          setModal(true);
                        });
                      }}
                      onError={(err) => {
                        setModalInfo(modals[2]);
                        setModal(true);
                      }}
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: totalAfterDiscount,
                              },
                              reference_id: "1",
                            },
                          ],
                          application_context: {
                            shipping_preference: "NO_SHIPPING",
                          },
                        });
                      }}
                    />
                    <div style={{ height: 5 }}></div>
                    <PayPalButtons
                      className={styles.buttonPayment}
                      style={{ shape: "pill" }}
                      disabled={totalAfterDiscount < 1}
                      fundingSource={FUNDING.CARD}
                      forceReRender={totalAfterDiscount}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then(function (details) {
                          setModalInfo(modals[0]);
                          submitPagoPaypal(details.id);
                          setModal(true);
                        });
                      }}
                      onError={(err) => {
                        console.log("error", err);
                        setModalInfo(modals[2]);
                        setModal(true);
                      }}
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: totalAfterDiscount,
                              },
                              reference_id: "1",
                            },
                          ],
                          application_context: {
                            shipping_preference: "NO_SHIPPING",
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        fullscreen={true}
        animation={true}
        centered={true}
        show={modalShown}
      >
        <ModalBody className={styles.modal} style={{}}>
          <Image style={{ width: 250 }} src={images.logoPurple} />
          <Image
            style={{
              width: 100,
              marginBottom: 15,
              marginTop: 80,
            }}
            src={modalInfo.image}
          />
          <H6.BOLD style={{ marginTop: 15, marginBottom: 10 }}>
            {modalInfo.title}
          </H6.BOLD>
          <PBODY.BOLD
            style={{
              fontSize: 16,
              maxWidth: 400,
              textAlign: "center",
              color: "#1A0063",
            }}
          >
            {modalInfo.message}
          </PBODY.BOLD>
          <Button
            onPress={closeModal}
            style={{ width: 400, marginTop: 60 }}
            title={"Cerrar"}
          />
        </ModalBody>
      </Modal>
      <style>{`
      .table-striped > tbody > tr:nth-of-type(odd){
      }`}</style>
    </AppLayout>
  );
};

export default CompanyCart;
