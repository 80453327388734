import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import colors from "../../Constants/colors";
import PBODY from "../Text/Pbody";
import styles from "./styles.module.css";

const WButton = ({
  // variant,
  style,
  // children,
  onPress,
  disabled = false,
  title,
  loading,
  form,
  icon,
  titleColor = "white",
}) => {
  const buttonStyle = {
    height: 53,
    backgroundColor: disabled ? "#E5E5E5" : colors.purpleDark,
    ...style,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    border: "none",
    borderColor: "transparent",
    boxShadow: "none",
  };

  return (
    <>
      {disabled ? (
        <>
          {form ? (
            <button
              type={"submit"}
              style={buttonStyle}
              className={styles.button2}
            >
              <PBODY.BOLD style={{ color: "white" }}>{title}</PBODY.BOLD>
            </button>
          ) : (
            <div style={buttonStyle} className={styles.button2}>
              <PBODY.BOLD style={{ color: "white" }}>{title}</PBODY.BOLD>
            </div>
          )}
        </>
      ) : (
        <>
          {form ? (
            <button
              type={"submit"}
              style={buttonStyle}
              className={styles.button}
            >
              <PBODY.BOLD style={{ color: "white" }}>
                {loading ? (
                  <PulseLoader color={"white"} size={8} loading={loading} />
                ) : (
                  title
                )}
              </PBODY.BOLD>
            </button>
          ) : (
            <div
              onClick={onPress}
              style={buttonStyle}
              className={styles.button}
            >
              <PBODY.BOLD style={{ color: titleColor }}>
                {icon && icon}
                {loading ? (
                  <PulseLoader color={"white"} size={8} loading={loading} />
                ) : (
                  title
                )}
              </PBODY.BOLD>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default WButton;

// WButton.propTypes = {
//   title: PropTypes.string
// }
