import {
  FILTER_REQUESTS,
  PASSWORD_VALIDATED,
  REMOVE_PLANS_FOR_USER,
  SET_COMPANY_EMPLOYEES,
  SET_COMPANY_REQUESTS,
  SET_PLANS_FOR_USER,
  SET_PLANS_FOR_USER_ASSGINED,
  SET_COMPANY_MOVEMENTS,
} from "../ActionTypes";

const initialState = {
  companyRequests: null,
  passwordValidated: false,
  companyEmployees: null,
  companyMovements: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_REQUESTS:
      return {
        ...state,
        companyRequests: action.data,
      };
    case FILTER_REQUESTS:
      const array = [...state.companyRequests];
      const filtered = array.filter((item) => item._id !== action.id);
      return {
        ...state,
        companyRequests: filtered,
      };
    case PASSWORD_VALIDATED:
      return {
        ...state,
        passwordValidated: true,
      };
    case SET_COMPANY_EMPLOYEES:
      return {
        ...state,
        companyEmployees: action.data,
      };
    case SET_COMPANY_MOVEMENTS:
      return {
        ...state,
        companyMovements: action.data,
      };
    case SET_PLANS_FOR_USER:
      const tempEmployees = [...state.companyEmployees];
      const index = tempEmployees.findIndex((item) => item._id === action.id);
      tempEmployees[index].activePlans = action.data;
      return {
        ...state,
        companyEmployees: tempEmployees,
      };

    case SET_PLANS_FOR_USER_ASSGINED:
      const tempEmployees2 = [...state.companyEmployees];
      const index2 = tempEmployees2.findIndex((item) => item._id === action.id);
      if (tempEmployees2[index2].activePlans?.length > 0) {
        tempEmployees2[index2].activePlans = [
          ...tempEmployees2[index2].activePlans,
          ...action.data,
        ];
      } else {
        tempEmployees2[index2].activePlans = action.data;
      }
      return {
        ...state,
        companyEmployees: tempEmployees2,
      };
    case REMOVE_PLANS_FOR_USER:
      const tempEmployees3 = [...state.companyEmployees];
      const index3 = tempEmployees3.findIndex((item) => item._id === action.id);
      tempEmployees3[index3].activePlans = tempEmployees3[
        index3
      ].activePlans.filter((val) => !action.data.includes(val));
      return {
        ...state,
        companyEmployees: tempEmployees3,
      };

    default:
      return state;
  }
};
export default reducer;
