import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Modal from "react-bootstrap/Modal";
import { IoAddOutline, IoTrashOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../Components/Button";
import PassesModal from "../../../Components/PassesModal";
import H4 from "../../../Components/Text/H4";
import AppLayout from "../../../Containers/AppLayout";
import {
  deleteUser,
  getCompanyEmployees,
  hideUsersModal,
  showUsersModal,
} from "../../../Store/Actions";
import AdminAddUsers from "../AdminAddUsers";
import styles from "./styles.module.css";

const AdminUsers = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const employees = useSelector((state) => state.admin.companyEmployees);
  const [products, setProducts] = useState([]);
  const employeesModal = useSelector((state) => state.ui.employeesModal);

  useEffect(() => {
    dispatch(getCompanyEmployees(user.company_id));
  }, []);

  useEffect(() => {
    if (employees?.length) {
      const auxProducts = employees.map((item, i) => {
        let product = {
          _id: item._id,
          name: `${item?.firstName} ${item?.lastName}`,
          id: item?.documentID,
          phone: item?.phone,
          email: item?.email,
          actions: "actions",
        };
        return product;
      });
      setProducts(auxProducts);
    }
  }, [employees]);

  useEffect(() => {
    dispatch(getCompanyEmployees());
  }, []);

  const columns = [
    {
      dataField: "_id",
      text: "Id",
      sort: true,
      style: {
        border: "none",
      },
      type: "string",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Nombre y Apellido",
      sort: true,
      style: {
        border: "none",
      },
      type: "date",
    },
    {
      dataField: "id",
      text: "Cédula",
      sort: true,
      style: {
        border: "none",
      },
      type: "string",
    },
    {
      dataField: "phone",
      text: "Teléfono",
      sort: true,
      style: {
        border: "none",
      },
      // sortFunc: (a, b, order, dataField, rowA, rowB) => {
      //   if (order === "asc") {
      //     return a.amount - b.amount;
      //   } else if (order === "desc") {
      //     return b.amount - a.amount;
      //   }
      // },
      type: "string",
    },
    {
      dataField: "email",
      text: "Correo",
      sort: true,
      style: {
        border: "none",
      },
      type: "string",
      formatter: (cell) => {
        return <div>{cell}</div>;
      },
      // sortFunc: (a, b, order, dataField, rowA, rowB) => {
      //   if (order === "asc") {
      //     return a - b;
      //   } else if (order === "desc") {
      //     return b - a;
      //   }
      // },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: true,
      style: {
        border: "none",
      },
      type: "string",
      formatter: (cell, row, rowIndex) => {
        return (
          <div className={styles.actions}>
            <PassesModal data={row} />
            <div
              onClick={() => {
                if (
                  window.confirm("¿Seguro que deseas eliminar este usuario?")
                ) {
                  dispatch(deleteUser(row._id));
                  // console.log(row);
                } else {
                  // setModalPasses(false);
                  // setSelectedPlan({});
                }
              }}
              className={styles.cancel}
            >
              <IoTrashOutline
                size={20}
                style={{ color: "#ec8686", marginLeft: -1 }}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;
  const options = {
    onSizePerPageChange: (sizePerPage, page) => {},
    onPageChange: (page, sizePerPage) => {},
  };

  return (
    <AppLayout sideBar>
      <div className={styles.homeWrapper}>
        <div className={styles.header}>
          <H4.BOLD style={{ marginTop: 35 }}>Lista de Usuarios</H4.BOLD>
          <Button
            onPress={() => dispatch(showUsersModal())}
            icon={
              <IoAddOutline
                strokeWidth={50}
                size={25}
                style={{
                  marginRight: 10,
                  strokeWidth: 110,
                }}
                color={"black"}
                // style={{ stroke: "white", strokeWidth: "10" }}
              />
            }
            style={{
              height: 40,
              paddingLeft: 10,
              paddingRight: 20,
              backgroundColor: "white",
              boxShadow: "0px 0px 15px 0 rgba(0, 0, 0, 0.2)",
            }}
            titleColor="black"
            title={"Agregar usuario"}
          />
        </div>
        <ToolkitProvider
          keyField="_id"
          data={products}
          columns={columns}
          search
        >
          {(props) => (
            <div className={styles.tableStyle}>
              <div className={styles.search}>
                <SearchBar
                  srText={null}
                  placeholder={"Buscar"}
                  {...props.searchProps}
                />
              </div>
              <BootstrapTable
                // remote
                noDataIndication={"No hay datos"}
                {...props.baseProps}
                keyField={"_id"}
                hover
                sort={{
                  order: "desc",
                  dataField: "date",
                }}
                pagination={paginationFactory(options)}
                rowStyle={{ border: "none" }}
                wrapperClasses="table-responsive"
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <Modal
        show={employeesModal}
        fullscreen={"md-down"}
        centered
        onHide={() => dispatch(hideUsersModal())}
        size={"lg"}
        dialogClassName={styles.dialog}
        contentClassName={styles.content}
      >
        <Modal.Header closeButton>
          <Modal.Title>Agregar usuarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AdminAddUsers />
        </Modal.Body>
      </Modal>
    </AppLayout>
  );
};

export default React.memo(AdminUsers);
