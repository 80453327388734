/* PLOP_INJECT_IMPORT */
import LoginAdmin from './LoginAdmin';
import RegisterCompany from './RegisterCompany';
import Register from './Register';
import Login from './Login';

const AuthScreens = {
  /* PLOP_INJECT_EXPORT */
   LoginAdmin: LoginAdmin,
   RegisterCompany: RegisterCompany,
   Register: Register,
   Login: Login,
};

export default AuthScreens;
