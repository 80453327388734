import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../Components/Button";
import H5 from "../../../Components/Text/H5";
import H6 from "../../../Components/Text/H6";
import AppLayout from "../../../Containers/AppLayout";
import { removeUser } from "../../../Store/Actions";
import styles from "./styles.module.css";

const Profile = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const logout = () => {
    dispatch(removeUser());
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/perfil" });
  }, []);

  return (
    <AppLayout>
      <Row style={{ marginTop: 35 }} className={"justify-content-evenly"}>
        <Col
          className={styles.notSelectable}
          xs="10"
          sm="10"
          md="8"
          lg="5"
          xl="5"
        >
          <Row style={{ marginBottom: 15 }}>
            <Col xs="6" sm="6" md="6" lg="6" xl="6">
              <H6.REGULAR>Nombre</H6.REGULAR>
              <H5.SEMIBOLD>{user?.firstName}</H5.SEMIBOLD>
            </Col>
            <Col xs="6" sm="6" md="6" lg="6" xl="6">
              <H6.REGULAR>Apellido</H6.REGULAR>
              <H5.SEMIBOLD>{user?.lastName}</H5.SEMIBOLD>
            </Col>
          </Row>
          <H6.REGULAR>Email</H6.REGULAR>
          <Row style={{ marginBottom: 15 }}>
            <Col xs="6" sm="6" md="6" lg="6" xl="6">
              <H5.SEMIBOLD>{user?.email}</H5.SEMIBOLD>
            </Col>
          </Row>
          <H6.REGULAR>Teléfono</H6.REGULAR>
          <Row style={{ marginBottom: 15 }}>
            <Col xs="6" sm="6" md="6" lg="6" xl="6">
              <H5.SEMIBOLD>{user?.phone}</H5.SEMIBOLD>
            </Col>
          </Row>
          <Button onPress={logout} title={"Cerrar sesion"} />
        </Col>
        <Col xs="10" sm="10" md="8" lg="5" xl="5"></Col>
      </Row>
    </AppLayout>
  );
};

export default Profile;
