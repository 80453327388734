const colors = {
  black: "#000000",
  white: "#FFFFFF",
  red: "#F72D67",
  yellow: "#FCCC0A",
  green: "#8DC641",
  purpleLight: "#9570FC",
  purpleDark: "#8B37FF",
};

export default colors;
