import { fetchPlans } from "../../Config/Services/App";
import { REMOVE_PLANS, SET_PLANS } from "../ActionTypes";

export const setPlans = (data) => {
  return {
    type: SET_PLANS,
    data: data,
  };
};

export const getPlans = () => {
  return (dispatch) => {
    dispatch(fetchPlans());
  };
};

export const removePlans = () => {
  return {
    type: REMOVE_PLANS,
  };
};
