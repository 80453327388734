import {
  AUTH_REMOVE_TOKEN,
  AUTH_SET_USER,
  REGISTER_ERROR,
  REGISTER_FAILED,
  REGISTER_SUCCESS,
  RESET_MODAL,
  UPDATE_BALANCE,
  UPDATE_USER,
} from "../ActionTypes";

const initialState = {
  logged: false,
  userExists: true,
  user: null,
  companySuccess: "",
  modal: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case AUTH_REMOVE_TOKEN:
      return {
        ...state,
        user: null,
      };

    case UPDATE_USER:
      let newDataUser = { ...action.user, token: state.user.token };
      let objJsonStr = JSON.stringify(newDataUser);
      let objJsonB64 = Buffer.from(objJsonStr).toString("base64");
      localStorage.setItem("user", objJsonB64);
      return {
        ...state,
        user: newDataUser,
      };
    case REGISTER_FAILED:
      return {
        ...state,
        companySuccess: false,
        modal: 1,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        companySuccess: true,
        modal: 0,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        companySuccess: false,
        modal: 2,
      };
    case RESET_MODAL:
      return {
        ...state,
        companySuccess: "false",
        user: null,
        modal: "",
      };
    case UPDATE_BALANCE: {
      return {
        ...state,
        user: {
          ...state.user,
          company: {
            ...state.user.company,
            balance: action.balance,
          },
        },
      };
    }
    default:
      return state;
  }
};
export default reducer;
