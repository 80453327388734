// import { useGA4React } from "ga-4-react";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Img from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import ReactGA from "react-ga4";
import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import images from "../../../Assets/Images";
import AuthCard from "../../../Components/AuthCard";
import CodeInput from "../../../Components/CodeInput";
import PBODY from "../../../Components/Text/Pbody";
import AppLayout from "../../../Containers/AppLayout";
import {
  authSms,
  authVerifySms,
  removeBadCodeEntry,
  removePhoneError,
} from "../../../Store/Actions";
import styles from "./styles.module.css";

const Login = (props) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [pin, setPin] = useState("");
  const [sendCode, setSendCode] = useState(false);
  const codeInputLogin = useSelector((state) => state.ui.codeInputLogin);
  const phoneError = useSelector((state) => state.ui.phoneError);
  const codeError = useSelector((state) => state.ui.codeError);
  // const X = useGA4React();

  const onChange = (value, country, e, formattedValue) => {
    setPhone(value);
    setCode(country.dialCode);
    dispatch(removePhoneError());
  };

  const submit = () => {
    const phoneValue = `+`.concat(phone);
    dispatch(authSms(phoneValue));
  };

  const submitCode = () => {
    const data = {
      phone: `+`.concat(phone),
      code: pin,
    };
    dispatch(authVerifySms(data));
  };

  const enableCodeSent = (pinCode) => {
    setPin(pinCode);
    setDisabled(false);
    setSendCode(true);
  };

  const removeCodeError = () => {
    dispatch(removeBadCodeEntry());
  };

  useEffect(() => {
    setDisabled(true);
  }, [codeInputLogin]);

  useEffect(() => {
    const x = phone.replace(code, "");
    if (x.length === 10) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [phone]);

  const triggerApple = () => {
    ReactGA.event({
      category: "App store",
      action: "go_to_appStore",
      label: "Go to the app store",
      value: 1,
    });
    window.open("https://apps.apple.com/us/app/la-wawa/id1566962710");
  };

  const triggerGoogle = () => {
    ReactGA.event({
      category: "Google play store",
      action: "go_to_googleStore",
      label: "Go to the google store",
      value: 1,
    });
    window.open(
      "https://play.google.com/store/apps/details?id=com.sparelabs.platform.rider.lawawa"
    );
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/login" });
  }, [ReactGA]);

  return (
    <AppLayout navBar={false} banners={true} fluid noAuth>
      <div className={styles.hole}>
        {/* <CircleTop /> */}
        <Container fluid>
          <Row className="justify-content-center">
            <Col
              style={{
                padding: 0,
                marginBottom: 50,
                marginTop: 50,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
              sm="auto"
              md="auto"
              xs="auto"
              lg="auto"
            >
              <Img
                style={{
                  maxHeight: 60,
                  alignSelf: "center",
                  marginBottom: 25,
                }}
                src={images.laWawaPurple}
              />
              <AuthCard
                title={"Inicia sesión"}
                subTitle1={!codeInputLogin ? "Ingresa tu" : "Ingresa el"}
                subTitle2={!codeInputLogin ? "número de teléfono" : "código"}
                message={
                  !codeInputLogin
                    ? "Te enviaremos un código SMS para poder entrar."
                    : `Te enviamos un código SMS al número +${phone}.`
                }
                buttonAction={!codeInputLogin ? submit : submitCode}
                buttonTitle={"Continuar"}
                buttonDisabled={disabled}
                login
                questions
              >
                {codeInputLogin && (
                  <CodeInput
                    onIncomplete={() => setDisabled(true)}
                    onComplete={enableCodeSent}
                    removeCodeError={removeCodeError}
                  />
                )}
                {!codeInputLogin && (
                  <PhoneInput
                    country={"ve"}
                    value={phone}
                    onChange={onChange}
                    preferredCountries={["ve"]}
                    enableSearch
                    localization={es}
                    searchPlaceholder={"Buscar"}
                    searchNotFound={"País no encontrado"}
                    autocompleteSearch
                    defaultErrorMessage={"Número no válido"}
                    masks={{ ve: "(...) ...-...." }}
                    countryCodeEditable={false}
                    containerStyle={{
                      display: "flex",
                      height: 50,
                      maxWidth: 414,
                    }}
                    buttonStyle={{
                      backgroundColor: "white",
                      height: "100%",
                      borderRadius: 5,
                      width: 60,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    inputStyle={{
                      borderWidth: 1,
                      borderRadius: 5,
                      textAlign: "left",
                      paddingLeft: 10,
                      paddingRight: 10,
                      height: "100%",
                      boderColor: "#BBBBBB",
                      marginLeft: 70,
                      maxWidth: 260,
                      fontFamily: "Montserrat",
                    }}
                  />
                )}
                {phoneError && (
                  <PBODY.SEMIBOLD style={{ marginTop: 25, color: "red" }}>
                    Teléfono no encontrado.
                  </PBODY.SEMIBOLD>
                )}
                {codeError && (
                  <PBODY.SEMIBOLD style={{ marginTop: 25, color: "red" }}>
                    Código inválido.
                  </PBODY.SEMIBOLD>
                )}
              </AuthCard>
            </Col>
          </Row>
        </Container>
        {/* <div className={styles.banners}>
          <Apple
            className={styles.banner}
            onClick={triggerApple}
            style={{ marginRight: 20 }}
            height={40}
          />
          <Google
            className={styles.banner}
            onClick={triggerGoogle}
            style={{ marginLeft: 20 }}
            height={40}
          />
        </div> */}
        {/* <CircleBottom /> */}
      </div>
    </AppLayout>
  );
};

export default Login;
