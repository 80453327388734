import React from "react";
import styles from "./styles.module.css";

const H3BOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H3BOLD].join(" ")}>
    {children}
  </div>
);
const H3SEMIBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H3SEMIBOLD].join(" ")}>
    {children}
  </div>
);
const H3REGULAR = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H3REGULAR].join(" ")}>
    {children}
  </div>
);

const H3 = {
  BOLD: ({ style, className, children }) =>
    H3BOLD({ style, className, children }),
  SEMIBOLD: ({ style, className, children }) =>
    H3SEMIBOLD({ style, className, children }),
  REGULAR: ({ style, className, children }) =>
    H3REGULAR({ style, className, children }),
};

export default H3;
