//AUTH
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
export const SET_SMS_CODE = "SET_SMS_CODE";
export const SET_EMAIL = "SET_EMAIL";
export const SET_FIRST_NAME = "SET_FIRST_NAME";
export const SET_LAST_NAME = "SET_LAST_NAME";
export const AUTH_SET_TOKEN = "AUTH_SET_TOKEN";
export const AUTH_REMOVE_TOKEN = "AUTH_REMOVE_TOKEN";
export const NO_EXISTING_USER = "NO_EXISTING_USER";
export const AUTH_CLEAR_USER = "AUTH_CLEAR_USER";
export const AUTH_SOCIAL_SIGN_UP = "AUTH_SOCIAL_SIGN_UP";
export const AUTH_SET_APPLE_NONCE = "AUTH_SET_APPLE_NONCE";
export const AUTH_SET_LOCATIONS = "AUTH_SET_LOCATIONS";
export const AUTH_SET_USER = "AUTH_SET_USER";
export const AUTH_SET_REFERRAL = "AUTH_SET_REFERRAL";
export const AUTH_SET_DEVICEID = "AUTH_SET_DEVICEID";
export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_USER = "UPDATE_USER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const RESET_MODAL = "RESET_MODAL";

//UI
export const START_AUTH_LOADING = "START_AUTH_LOADING";
export const STOP_AUTH_LOADING = "STOP_AUTH_LOADING";
export const ENABLE_CODE_INPUT = "ENABLE_CODE_INPUT";
export const DISABLE_CODE_INPUT = "DISABLE_CODE_INPUT";
export const START_PAYMENT_SAVING = "START_PAYMENT_SAVING";
export const STOP_PAYMENT_SAVING = "STOP_PAYMENT_SAVING";
export const FINISH_PAYMENT_SAVING = "FINISH_PAYMENT_SAVING";
export const UI_CLEAR = "UI_CLEAR";
export const SET_PHONE_ERROR = "SET_PHONE_ERROR";
export const REMOVE_PHONE_ERROR = "REMOVE_PHONE_ERROR";
export const SET_CODE_ERROR = "SET_CODE_ERROR";
export const REMOVE_CODE_ERROR = "REMOVE_CODE_ERROR";
export const CHANGE_SIDEBAR = "CHANGE_SIDEBAR";
export const SHOW_ADD_USER_MODAL = "SHOW_ADD_USER_MODAL";
export const HIDE_ADD_USER_MODAL = "HIDE_ADD_USER_MODAL";
export const SHOW_PROMO_MODAL = "SHOW_PROMO_MODAL";
export const HIDE_PROMO_MODAL = "HIDE_PROMO_MODAL";
export const SET_BAD_PROMO_CODE = "SET_BAD_PROMO_CODE";
export const SET_GOOD_PROMO_CODE = "SET_GOOD_PROMO_CODE";
export const START_FETCHING_PROMO = "START_FETCHING_PROMO";

//PLANS
export const SET_PLANS = "SET_PLANS";
export const REMOVE_PLANS = "REMOVE_PLANS";

//PAYMENTS
export const SET_PAYMENTS = "SET_PAYMENTS";
export const SET_RATE = "SET_RATE";
export const REMOVE_PAYMENTS = "REMOVE_PAYMENTS";

//ADMIN
export const GET_COMPANY_REQUESTS = "GET_COMPANY_REQUESTS";
export const UPLOAD_COMPANY_CONTACTS = "UPLOAD_COMPANY_CONTACTS";
export const CREATE_COMPANY_USER = "CREATE_COMPANY_USER";
export const SET_COMPANY_REQUESTS = "SET_COMPANY_REQUESTS";
export const FILTER_REQUESTS = "FILTER_REQUESTS";
export const PASSWORD_VALIDATED = "PASSWORD_VALIDATED";
export const SET_COMPANY_EMPLOYEES = "SET_COMPANY_EMPLOYEES";
export const SET_PLANS_FOR_USER = "SET_PLANS_FOR_USER";
export const SET_PLANS_FOR_USER_ASSGINED = "SET_PLANS_FOR_USER_ASSGINED";
export const UPDATE_BALANCE = "UPDATE_BALANCE";
export const REMOVE_PLANS_FOR_USER = "REMOVE_PLANS_FOR_USER";
export const SET_COMPANY_MOVEMENTS = "SET_COMPANY_MOVEMENTS";
