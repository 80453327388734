import { fetchProfile, fetchProfileAdmin } from "../../Config/Services/App";
import {
  companyRequest,
  fetchSignUpCode,
  fetchSms,
  fetchSmsCode,
  signUpClient,
} from "../../Config/Services/Auth";
import {
  AUTH_REMOVE_TOKEN,
  AUTH_SET_USER,
  REGISTER_ERROR,
  REGISTER_FAILED,
  REGISTER_SUCCESS,
  RESET_MODAL,
  UPDATE_BALANCE,
  UPDATE_USER,
} from "../ActionTypes";
import { removePayments, removePlans, uiClear } from "./index";

// export const logout = () => {
//   localStorage.removeItem('token');
//   localStorage.removeItem('expirationDate');
//   localStorage.removeItem('userId');
//   return {
//     type: actionTypes.AUTH_LOG_OUT
//   }
// }

export const authSms = (data) => {
  return (dispatch) => {
    dispatch(fetchSms(data));
  };
};

export const authVerifySms = (data) => {
  return (dispatch) => {
    dispatch(fetchSmsCode(data));
  };
};

export const authSetUser = (data) => {
  return {
    type: AUTH_SET_USER,
    user: data,
  };
};

export const authRemoveUSer = () => {
  return {
    type: AUTH_REMOVE_TOKEN,
  };
};

export const setUser = (data) => {
  return (dispatch) => {
    let objJsonStr = JSON.stringify(data);
    let objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    localStorage.setItem("user", objJsonB64);
    dispatch(authSetUser(data));
    setTimeout(() => {
      dispatch(uiClear());
    }, 1000);
  };
};

export const removeUser = () => {
  return (dispatch) => {
    localStorage.removeItem("user");
    dispatch(authRemoveUSer());
    dispatch(removePayments());
    dispatch(removePlans());
  };
};

export const getProfile = () => {
  return (dispatch) => {
    dispatch(fetchProfile());
  };
};

export const getProfileAdmin = () => {
  return (dispatch) => {
    dispatch(fetchProfileAdmin());
  };
};

export const updateUser = (data) => {
  return {
    type: UPDATE_USER,
    user: data,
  };
};

export const companySignUpRequest = (data) => {
  return (dispatch) => {
    dispatch(companyRequest(data));
  };
};

export const smsSignUp = (data) => {
  return (dispatch) => {
    dispatch(fetchSignUpCode(data));
  };
};

export const signUp = (data) => {
  return (dispatch) => {
    dispatch(signUpClient(data));
  };
};

export const setSuccessRegister = () => {
  return {
    type: REGISTER_SUCCESS,
  };
};

export const setFailedRegister = () => {
  return {
    type: REGISTER_FAILED,
  };
};

export const setErrorRegister = () => {
  return {
    type: REGISTER_ERROR,
  };
};

export const resetModal = () => {
  return {
    type: RESET_MODAL,
  };
};

export const updateCompanyBalance = (data) => {
  return {
    type: UPDATE_BALANCE,
    balance: data,
  };
};
