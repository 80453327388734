import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
// import GoogleAnalytics from "./Utility";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import "./App.scss";
import colors from "./Constants/colors";
import AppScreens from "./Screens/App";
import AuthScreens from "./Screens/Auth";
import {
  authSetUser,
  getCompanyEmployees,
  getPayments,
  getPlans,
  getProfile,
  getProfileAdmin,
} from "./Store/Actions";

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const [finishLoading, setFinishLoading] = useState(false);

  const initialOptions = {
    "client-id": process.env.REACT_APP_PAY_PAL_CLIEND_ID,
    currency: "USD",
    intent: "capture",
    locale: "es_US",
  };

  const checkIfValidToken = async (token, userJSON) => {
    const decodedToken = await jwtDecode(token);
    let tokenDate = new Date(decodedToken.exp * 1000);
    if (tokenDate < new Date()) {
      localStorage.removeItem("user");
    } else {
      dispatch(authSetUser(userJSON));
      if (user?.type === "Client") {
        dispatch(getProfile());
      } else {
        dispatch(getProfileAdmin());
      }
    }
    setFinishLoading(true);
  };

  useEffect(() => {
    isLogged();
  }, [user?.token]);

  useEffect(() => {
    ReactGA.initialize("G-X7X8GF0BHW");
    if (user?.token) {
      history.replace("/");
      if (user?.type === "Client") {
        dispatch(getPayments());
      }
      if (user?.type === "Admin") {
        dispatch(getProfileAdmin());
        dispatch(getCompanyEmployees());
      }
      dispatch(getPlans());
    }
  }, [user?.token]);

  const isLogged = () => {
    try {
      const userLocal = localStorage.getItem("user");
      const decodedUser = atob(userLocal);
      const userJSON = userLocal != null ? JSON.parse(decodedUser) : null;
      if (userJSON) {
        setTimeout(() => {
          checkIfValidToken(userJSON.token, userJSON);
        }, 1000);
      } else {
        setTimeout(() => {
          setFinishLoading(true);
        }, 1000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loaderIndicator = (
    <div className={"loaderWawa"}>
      <Loader
        type="TailSpin"
        color={colors.purpleDark}
        height={100}
        width={100}
        // timeout={3000} //3 secs
      />
    </div>
  );

  let routes = (
    <Switch>
      <Route path="/login" component={AuthScreens.Login} />
      <Route path="/empresas" component={AuthScreens.LoginAdmin} />
      <Route path="/registro" component={AuthScreens.Register} />
      <Route
        path="/registro-de-empresa"
        component={AuthScreens.RegisterCompany}
      />
      <Route path="/" exact component={AuthScreens.Login} />
      <Redirect to="/" />
    </Switch>
  );

  if (user?.type === "Admin") {
    routes = (
      <Switch>
        <Route path="/agregar-saldo" exact component={AppScreens.CompanyCart} />
        <Route path="/peticiones" exact component={AppScreens.AdminRequests} />
        <Route path="/ajustes" exact component={AppScreens.Settings} />
        <Route
          path="/movimientos"
          exact
          component={AppScreens.AdminWalletMovements}
        />
        <Route path="/" exact component={AppScreens.AdminUsers} />
        <Route path="/404" component={AppScreens.Page404} />
        <Route path="*" component={AppScreens.Home} />
        {/* <Route
          path="/agregar-usuario"
          exact
          component={AppScreens.AdminAddUsers}
        /> */}
        {/* <Redirect to="/404" /> */}
      </Switch>
    );
  }

  if (user?.type === "Client") {
    routes = (
      <Switch>
        <Route path="/pases" exact component={AppScreens.Payments} />
        <Route path="/usuarios" exact component={AppScreens.AdminUsers} />
        <Route path="/peticiones" exact component={AppScreens.AdminRequests} />
        <Route
          path="/agregar-usuario"
          exact
          component={AppScreens.AdminAddUsers}
        />
        <Route path="/" exact component={AppScreens.Home} />
        <Route path="/404" component={AppScreens.Page404} />
        <Route path="*" component={AppScreens.Home} />
        {/* <Redirect to="/404" /> */}
      </Switch>
    );
  }

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div>{finishLoading && routes}</div>
      <div>{!finishLoading && loaderIndicator}</div>

      {process.env.NODE_ENV === "production" && (
        <Helmet>
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js-na1.hs-scripts.com/20925848.js"
          ></script>
        </Helmet>
      )}
    </PayPalScriptProvider>
  );
};

export default App;
