/* PLOP_INJECT_IMPORT */
import Page404 from "./404";
import AdminAddUsers from "./AdminAddUsers";
import AdminRequests from "./AdminRequests";
import Settings from "./AdminSettings";
import AdminUsers from "./AdminUsers";
import CompanyCart from "./CompanyCart";
import Home from "./Home";
import Payments from "./Payments";
import Profile from "./Profile";
import WaitList from "./WaitList";
import AdminWalletMovements from "./AdminWalletMovements";
const AppScreens = {
  /* PLOP_INJECT_EXPORT */
  Settings: Settings,
  CompanyCart: CompanyCart,
  AdminUsers: AdminUsers,
  AdminAddUsers: AdminAddUsers,
  AdminRequests: AdminRequests,
  Page404: Page404,
  WaitList: WaitList,
  Payments: Payments,
  Profile: Profile,
  Home: Home,
  AdminWalletMovements: AdminWalletMovements,
};

export default AppScreens;
