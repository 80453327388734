import axios from "axios";

const wawaAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    apiKey: process.env.REACT_APP_API_KEY,
  },
});

export default wawaAPI;

export const SMS_CODE = "/web/phone/sms";
export const SMS_VERIFY = "/web/login";
export const SIGN_UP = "/users/phone/signup";
export const SOCIAL_VERIFY = "/users/social/check";
export const SOCIAL_SIGN_UP = "/users/social/signup";
export const USERS = "/users";
export const LOG_OUT = "/users/logout";
export const ADD_HOME_LOCATION = "/users/locations/home";
export const ADD_WORK_LOCATION = "/users/locations/work";
export const GET_NEW_TOKEN = "/users/token";
export const AVAILABLE_INVITES = "/users/invites/available";
export const UPDATE_PHONE = "/users/phone/update";
export const UPDATE_PHONE_SMS = "/users/phone/verify";
export const COMPANY_CONTACTS = "/companyContacts";
export const ID_IMAGE = "/users/upload/idDocument";
export const USER_IMAGE = "/users/upload/profile";
export const USER_PROFILE = "/users/profile/data";
export const APPLY_PROMO = "/users/promos";
export const CONFIG = "/config";
export const UBII_PAYMENT = "/payments/ubii";
export const PAYPAL_PAYMENT = "/payments/paypal";
export const PLANS = "/plans";
export const PAYMENTS = "/payments";
export const PROFILE = "/web/profile";
export const COMPANY_SIGNUP = "/company/admin";
export const SIGN_UP_WEB = "/web/signup";
export const SEND_CODE = "/phone/sms";
export const USER_ACTIVE_PLANS = "/users/plans/active";
export const USER_DELETE_ACTIVE_PLANS = "/users/promos"; // DELETE
export const USER_ASSIGN_PLANS = "/users/allocations"; //POST

//ADMIN ENDPOINTS
export const COMPANY_REQUESTS = "/company/employees/requests";
export const UPLOAD_CONTACTS = "/company/employees/upload";
export const CREATE_USER = "/company/employees";
export const COMPANY_LOGIN = "/company/login";
export const RESET_PASSWORD = "/company/admin/password"; //PATCH
export const VALIDATE_PASSWORD = "/company/admin/validate"; //POST
export const COMPANY_EMPLOYEES = "/company/employees";
export const COMPANY_UBII_PAYMENT = "/company/payments/ubii";
export const COMPANY_PAYPAL_PAYMENT = "/company/payments/paypal";
export const COMPANY_USER_PROFILE = "/company/admin/profile";
export const DELETE_USER_PROFILE = "/company/employees/delete"; //PATCH
export const COMPANY_MOVEMENTS = "/company/movements";
