import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Img from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import images from "../../Assets/Images";
import NavigationItems from "../../Components/NavigationItems";
import PromoModal from "../../Components/PromoModal";
import SideBar from "../../Components/SideBar";
import { removeUser, showPromoModal } from "../../Store/Actions";
import styles from "./styles.module.css";

const AppLayout = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const ui = useSelector((state) => state.ui);
  const [show, setShow] = useState(false);
  const fetchingPromo = useSelector((state) => state.ui.fetchingPromo);
  const promoValid = useSelector((state) => state.ui.promoValid);

  const logOut = () => {
    dispatch(removeUser());
  };

  const goToLogin = () => {
    history.replace("/");
  };

  const history = useHistory();

  const {
    children,
    fluid = false,
    background,
    navBar = true,
    circleBackground,
    auth,
    noAuth,
    sideBar = false,
    overflow = true,
  } = props;

  return (
    <div
      className={`${styles.background} ${
        circleBackground ? styles.circleBackground : ""
      }`}
    >
      {navBar && (
        <Navbar
          sticky={"top"}
          fixed={"top"}
          className={styles.navbar}
          collapseOnSelect
          expand="lg"
          bg="primary"
          variant="dark"
        >
          <Navbar.Brand href="/">
            <Img style={{ maxHeight: 45 }} src={images.logoHeader} />
          </Navbar.Brand>
          {!noAuth && <Navbar.Toggle aria-controls="responsive-navbar-nav" />}
          {!noAuth && (
            <Navbar.Collapse id="responsive-navbar-nav">
              <div className={styles.nav}>
                <NavigationItems
                  noAuth={noAuth}
                  auth={auth}
                  logout={logOut}
                  goToLogin={goToLogin}
                  access={user?.hasAccess}
                  type={user?.type}
                  user={user}
                  modalTrigger={() => dispatch(showPromoModal())}
                />
              </div>
            </Navbar.Collapse>
          )}
        </Navbar>
      )}
      <div className={styles.withSideBar}>
        {sideBar && user?.type === "Admin" && <SideBar />}
        <div className={overflow ? styles.overflow : styles.noOverflow}>
          <Container fluid={fluid}>
            <div
              className={`row ${background ? styles.backgroundColumns : ""}`}
            >
              {children}
            </div>
          </Container>
        </div>
      </div>
      <PromoModal />
    </div>
  );
};

export default AppLayout;
