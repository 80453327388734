import React from "react";
import styles from "./styles.module.css";

const H6BOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H6BOLD].join(" ")}>
    {children}
  </div>
);
const H6SEMIBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H6SEMIBOLD].join(" ")}>
    {children}
  </div>
);
const H6REGULAR = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H6REGULAR].join(" ")}>
    {children}
  </div>
);

const H6 = {
  BOLD: ({ style, className, children }) =>
    H6BOLD({ style, className, children }),
  SEMIBOLD: ({ style, className, children }) =>
    H6SEMIBOLD({ style, className, children }),
  REGULAR: ({ style, className, children }) =>
    H6REGULAR({ style, className, children }),
};

export default H6;
