import React from "react";
import { IoCheckmarkCircleSharp, IoEllipseOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import colors from "../../Constants/colors";
import H5 from "../Text/H5";
import H6 from "../Text/H6";
import styles from "./styles.module.css";

const Package = ({
  selected = false,
  cost,
  time,
  type,
  name,
  item,
  onPress,
  disabled,
}) => {
  const dispatch = useDispatch();

  return (
    <div
      onClick={!disabled ? onPress : {}}
      style={{ background: disabled ? "#DEDEDE" : "white" }}
      className={styles.package}
    >
      <div className={styles.info}>
        <div className={styles.infoWrapper}>
          <div className={styles.detail}>
            {selected ? (
              <IoCheckmarkCircleSharp
                style={{ marginRight: 10 }}
                color={colors.purpleLight}
                size={25}
              />
            ) : (
              <IoEllipseOutline
                style={{ marginRight: 10 }}
                color={"grey"}
                size={25}
              />
            )}
            <H6.SEMIBOLD style={{ color: "black" }}>{name}</H6.SEMIBOLD>
          </div>
          <div>
            <H5.BOLD style={{ color: "black" }}>${cost}</H5.BOLD>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Package;
