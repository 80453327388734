import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import ReactGA from "react-ga4";
import { Controller, useForm } from "react-hook-form";
import { IoInformationCircleOutline } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import images from "../../../Assets/Images";
import AuthCard from "../../../Components/AuthCard";
import Button from "../../../Components/Button";
import CodeInput from "../../../Components/CodeInput";
import H2 from "../../../Components/Text/H2";
import PTitle from "../../../Components/Text/PTitle";
import { signUpClient } from "../../../Config/Services/Auth";
import AppLayout from "../../../Containers/AppLayout";
import { authSms, removeBadCodeEntry, smsSignUp } from "../../../Store/Actions";
import styles from "./styles.module.css";

const Register = (props) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [pin, setPin] = useState("");
  const [sendCode, setSendCode] = useState(false);
  const codeInputLogin = useSelector((state) => state.ui.codeInputLogin);
  const authLoading = useSelector((state) => state.ui.authLoading);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    getValues,
  } = useForm();
  const onSubmit = (data, e) => {
    const phoneValue = `+`.concat(data.phoneNumber);
    dispatch(smsSignUp(phoneValue));
  };
  const removeCodeError = () => {
    dispatch(removeBadCodeEntry());
  };
  const submitErrorHandler = (errors, e) => console.log(errors);

  const onChange = (value, country, e, formattedValue) => {
    // setPhone(value);
    // setCode(country.dialCode);
    // dispatch(removePhoneError());
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/register" });
  }, [ReactGA]);

  const submit = () => {
    const phoneValue = `+`.concat(phone);
    dispatch(authSms(phoneValue));
  };

  const submitCode = () => {
    const user = getValues();
    const phoneValue = `+`.concat(user?.phoneNumber);
    const data = {
      user: { ...user, phoneNumber: phoneValue },
      pin,
    };
    dispatch(signUpClient(data));
  };

  const enableCodeSent = (pinCode) => {
    setPin(pinCode);
    setDisabled(false);
    setSendCode(true);
  };

  // useEffect(() => {
  //   setDisabled(true);
  // }, [codeInputLogin]);

  useEffect(() => {
    const x = phone.replace(code, "");
    if (x.length === 10) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [phone]);

  return (
    <AppLayout navBar={false} overflow={false} fluid auth>
      <Col className={styles.background}>
        <Container fluid>
          <Row className="justify-content-center align-items-center">
            <Image
              style={{
                maxHeight: 60,
                alignSelf: "center",
                marginBottom: 25,
                width: "auto",
                marginTop: 70,
              }}
              src={images.laWawaPurple}
            />
            {codeInputLogin && (
              <Row style={{ padding: 0 }} className={"justify-content-center"}>
                <Col
                  className={"d-flex justify-content-center"}
                  xs="auto"
                  sm="auto"
                  md="8"
                  lg="6"
                >
                  <AuthCard
                    title={"Registro"}
                    subTitle1={"Ingresa el"}
                    subTitle2={"código"}
                    message={`Te enviamos un código SMS al número +${phone}.`}
                    buttonAction={submitCode}
                    buttonTitle={"Continuar"}
                    buttonDisabled={disabled}
                    login
                  >
                    <CodeInput
                      onIncomplete={() => setDisabled(true)}
                      onComplete={enableCodeSent}
                      removeCodeError={removeCodeError}
                    />
                  </AuthCard>
                  <div className={styles.question}>
                    ¿Ya tienes una cuenta?{" "}
                    <Link className={styles.register} to="/">
                      <span>Ingresa aqui</span>
                    </Link>
                  </div>
                </Col>
              </Row>
            )}
            {!codeInputLogin && (
              <Row style={{ padding: 0 }} className="justify-content-center">
                <Col className={styles.card} xs="auto" sm="auto" md="8" lg="6">
                  <H2.BOLD
                    style={{
                      textAlign: "center",
                      paddingTop: 45,
                      paddingBottom: 45,
                    }}
                  >
                    Registro
                  </H2.BOLD>
                  <form onSubmit={handleSubmit(onSubmit, submitErrorHandler)}>
                    <Row className={"justify-content-center"}>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <div className={"d-flex"}>
                          <PTitle.SEMIBOLD>Nombre </PTitle.SEMIBOLD>
                          <PTitle.SEMIBOLD className={styles.required}>
                            *
                          </PTitle.SEMIBOLD>
                        </div>
                        <input
                          className={styles.input}
                          type="text"
                          autoComplete="off"
                          autoCorrect="off"
                          {...register("firstName", {
                            required: true,
                            maxLength: 80,
                          })}
                          required
                        />
                        <span
                          className={
                            errors.firstName
                              ? styles.errors
                              : styles.errorsHidden
                          }
                        >
                          Este campo es requerido
                        </span>
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <div className={"d-flex"}>
                          <PTitle.SEMIBOLD>Apellido </PTitle.SEMIBOLD>
                          <PTitle.SEMIBOLD className={styles.required}>
                            *
                          </PTitle.SEMIBOLD>
                        </div>
                        <input
                          className={styles.input}
                          type="text"
                          autoComplete="off"
                          autoCorrect="off"
                          {...register("lastName", {
                            required: true,
                            maxLength: 100,
                          })}
                          required
                        />
                        <span
                          className={
                            errors.lastName
                              ? styles.errors
                              : styles.errorsHidden
                          }
                        >
                          Este campo es requerido
                        </span>
                      </Col>
                    </Row>
                    <Row className={"justify-content-center"}>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <div className={"d-flex"}>
                          <PTitle.SEMIBOLD>Número de cédula </PTitle.SEMIBOLD>
                          <PTitle.SEMIBOLD className={styles.required}>
                            *
                          </PTitle.SEMIBOLD>
                        </div>
                        <input
                          className={styles.input}
                          type="number"
                          autoComplete="off"
                          autoCorrect="off"
                          {...register("userID", {
                            required: true,
                          })}
                          required
                        />
                        <span
                          className={
                            errors.userID ? styles.errors : styles.errorsHidden
                          }
                        >
                          Este campo es requerido
                        </span>
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <div className={"d-flex"}>
                          <PTitle.SEMIBOLD>Correo electrónico </PTitle.SEMIBOLD>
                          <PTitle.SEMIBOLD className={styles.required}>
                            *
                          </PTitle.SEMIBOLD>
                        </div>

                        <input
                          className={styles.input}
                          type="email"
                          autoComplete="off"
                          autoCorrect="off"
                          {...register("email", {
                            required: true,
                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                          required
                        />
                        <span
                          className={
                            errors.email ? styles.errors : styles.errorsHidden
                          }
                        >
                          Este campo es requerido
                        </span>
                      </Col>
                    </Row>
                    <Row className={"justify-content-center"}>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <div className={"d-flex"}>
                          <PTitle.SEMIBOLD>Teléfono </PTitle.SEMIBOLD>
                          <PTitle.SEMIBOLD className={styles.required}>
                            *
                          </PTitle.SEMIBOLD>
                        </div>
                        <Controller
                          name="phoneNumber"
                          control={control}
                          defaultValue={false}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              country={"ve"}
                              value={phone}
                              onChange={onChange}
                              preferredCountries={["ve"]}
                              enableSearch
                              localization={es}
                              searchPlaceholder={"Buscar"}
                              searchNotFound={"País no encontrado"}
                              autocompleteSearch
                              defaultErrorMessage={"Número no válido"}
                              masks={{ ve: "(...) ...-...." }}
                              countryCodeEditable={false}
                              inputClass={styles.test}
                              containerStyle={styles.test}
                              containerStyle={{
                                display: "flex",
                                height: 50,
                                width: "100%",
                              }}
                              buttonStyle={{
                                backgroundColor: "white",
                                height: "100%",
                                borderRadius: 5,
                                width: 60,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              inputStyle={{
                                borderWidth: 1,
                                borderRadius: 5,
                                textAlign: "left",
                                paddingLeft: 10,
                                paddingRight: 10,
                                height: 50,
                                boderColor: "red",
                                marginLeft: 70,
                                width: "100%",
                                fontFamily: "Montserrat",
                                fontSize: "1rem",
                                color: "black",
                              }}
                            />
                          )}
                        />
                        <span
                          className={
                            errors.phoneNumber
                              ? styles.errors
                              : styles.errorsHidden
                          }
                        >
                          Este campo es requerido
                        </span>
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <div className={"d-flex"}>
                          <PTitle.SEMIBOLD>
                            Empresa en la que trabaja
                          </PTitle.SEMIBOLD>
                          <PTitle.SEMIBOLD className={styles.required}>
                            *
                          </PTitle.SEMIBOLD>
                        </div>
                        <input
                          className={styles.input}
                          type="text"
                          autoComplete="off"
                          autoCorrect="off"
                          {...register("worksFor", {
                            required: true,
                          })}
                          required
                        />
                        <span
                          className={
                            errors.worksFor
                              ? styles.errors
                              : styles.errorsHidden
                          }
                        >
                          Este campo es requerido
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <div className={"d-flex align-items-center"}>
                          <PTitle.SEMIBOLD>Código Empresarial</PTitle.SEMIBOLD>

                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-info`}>
                                Disfruta de beneficios adicionales si tu empresa
                                se encuentra aliada con La Wawa
                              </Tooltip>
                            }
                          >
                            <div>
                              <IoInformationCircleOutline
                                className={"ms-2"}
                                size={20}
                              />
                            </div>
                          </OverlayTrigger>
                        </div>

                        <input
                          className={styles.input}
                          type="text"
                          autoComplete="off"
                          autoCorrect="off"
                          {...register("enterpriseCode", {
                            required: false,
                          })}
                        />
                      </Col>
                    </Row>
                    <Row className={"justify-content-center mt-3"}>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <Button
                          variant={"primary"}
                          title={"Registrarse"}
                          loading={authLoading}
                          form
                          style={{
                            width: "100%",
                            padding: 10,
                            marginTop: 20,
                            marginBottom: 10,
                            border: "none",
                          }}
                        />
                      </Col>
                    </Row>
                  </form>
                  <div className={styles.question}>
                    ¿Ya tienes una cuenta?{" "}
                    <Link className={styles.register} to="/">
                      <span>Ingresa aqui</span>
                    </Link>
                  </div>
                </Col>
              </Row>
            )}
          </Row>
        </Container>
      </Col>
    </AppLayout>
  );
};

export default Register;
