import {
  CHANGE_SIDEBAR,
  DISABLE_CODE_INPUT,
  ENABLE_CODE_INPUT,
  FINISH_PAYMENT_SAVING,
  HIDE_PROMO_MODAL,
  REMOVE_CODE_ERROR,
  REMOVE_PHONE_ERROR,
  SET_BAD_PROMO_CODE,
  SET_CODE_ERROR,
  SET_GOOD_PROMO_CODE,
  SET_PHONE_ERROR,
  SHOW_PROMO_MODAL,
  START_AUTH_LOADING,
  START_FETCHING_PROMO,
  START_PAYMENT_SAVING,
  STOP_AUTH_LOADING,
  STOP_PAYMENT_SAVING,
  UI_CLEAR,
  SHOW_ADD_USER_MODAL,
  HIDE_ADD_USER_MODAL,
} from "../ActionTypes";

export const startAuthLoading = () => {
  return {
    type: START_AUTH_LOADING,
  };
};

export const stopAuthLoading = () => {
  return {
    type: STOP_AUTH_LOADING,
  };
};

export const enableCodeInput = () => {
  return {
    type: ENABLE_CODE_INPUT,
  };
};

export const disableCodeInput = () => {
  return {
    type: DISABLE_CODE_INPUT,
  };
};

export const startPaymentSaving = () => {
  return {
    type: START_PAYMENT_SAVING,
  };
};

export const stopPaymentSaving = () => {
  return {
    type: STOP_PAYMENT_SAVING,
  };
};

export const finishPaymentSaving = () => {
  return {
    type: FINISH_PAYMENT_SAVING,
  };
};

export const uiClear = () => {
  return {
    type: UI_CLEAR,
  };
};

export const enableErrorPhone = () => {
  return {
    type: SET_PHONE_ERROR,
  };
};

export const removePhoneError = () => {
  return {
    type: REMOVE_PHONE_ERROR,
  };
};

export const setBadCodeEntry = () => {
  return {
    type: SET_CODE_ERROR,
  };
};

export const removeBadCodeEntry = () => {
  return {
    type: REMOVE_CODE_ERROR,
  };
};

export const changeSideBar = () => {
  return {
    type: CHANGE_SIDEBAR,
  };
};

export const hideUsersModal = () => {
  return {
    type: HIDE_ADD_USER_MODAL,
  };
};

export const showUsersModal = () => {
  return {
    type: SHOW_ADD_USER_MODAL,
  };
};

export const showPromoModal = () => {
  return {
    type: SHOW_PROMO_MODAL,
  };
};

export const hidePromoModal = () => {
  return {
    type: HIDE_PROMO_MODAL,
  };
};

export const setBadPromoCode = () => {
  return {
    type: SET_BAD_PROMO_CODE,
  };
};

export const setGoodPromoCode = () => {
  return {
    type: SET_GOOD_PROMO_CODE,
  };
};

export const startFetchingPromo = () => {
  return {
    type: START_FETCHING_PROMO,
  };
};
