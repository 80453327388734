import moment from "moment";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { IoCheckmarkCircleSharp, IoTrashOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import colors from "../../Constants/colors";
import {
  getActivePassesUser,
  setAssignPass,
  setDeletePass,
} from "../../Store/Actions";
import Button from "../Button";
import PBODY from "../Text/Pbody";
import styles from "./styles.module.css";

const PassesModal = (props) => {
  const dispatch = useDispatch();
  const [modalPasses, setModalPasses] = useState(false);
  const plans = useSelector((state) => state.plans.plans);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [finishLoading, setFinishLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const activePlans = useSelector(
    (state) =>
      state.admin.companyEmployees.find((user) => user._id === props.data._id)
        .activePlans
  );
  const [plansToDelete, setPlansToDelete] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [deleted, setDeleted] = useState(false);

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  useEffect(() => {
    setFinishLoading(false);
  }, []);

  const handleClose = () => {
    if (
      window.confirm(
        "¿Seguro que deseas salir? Al no confirmar perderás todos tus cambios"
      )
    ) {
      setModalPasses(false);
      setSelectedPlan("");
    } else {
      // setModalPasses(false);
      // setSelectedPlan({});
    }
  };

  const resetInfo = () => {
    setModalPasses(false);
    setSelectedPlan("");
    setPlansToDelete([]);
  };

  const confirmPlans = () => {
    if (selectedPlan !== {}) {
      const data = [
        {
          userID: props.data._id,
          planID: selectedPlan._id,
        },
      ];
      if (parseFloat(selectedPlan?.cost) > user.company.balance) {
        alert("No tienes suficiente saldo para asignar este plan");
      } else {
        dispatch(setAssignPass(props.data._id, data)).then((response) => {
          resetInfo();
        });
      }
    }
    if (plansToDelete?.length > 0) {
      dispatch(setDeletePass(props.data._id, plansToDelete)).then(
        (response) => {
          resetInfo();
        }
      );
    }
  };

  const setPlan = (data, index) => {
    const x = plansToDelete.findIndex((item) => item._id === data._id);
    console.log(x);
    if (x === -1) {
      //remove
      setPlansToDelete([...plansToDelete, data]);
    } else {
      //add
      setPlansToDelete(plansToDelete.filter((item) => item._id !== data._id));
    }
  };

  const Plan = ({ plan, index }) => {
    return (
      <div
        onClick={() => {
          selectPlan(plan);
        }}
        className={styles.plan}
        key={index}
      >
        <div className={styles.select}>
          <div className={styles.outer}>
            <div
              className={plan._id === selectedPlan._id ? styles.inner : ""}
            ></div>
          </div>
          <PBODY.SEMIBOLD>{plan.name}</PBODY.SEMIBOLD>
        </div>
        <div className={styles.cost}>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(plan.cost)}
        </div>
      </div>
    );
  };

  const ActivePlan = ({ data, index }) => {
    const x = plansToDelete.findIndex((item) => item._id === data._id);
    return (
      <div className={styles.plan2} key={index}>
        <div className={styles.select}>
          <div className={styles.outer}>
            {x !== -1 && <IoCheckmarkCircleSharp color={colors.purpleLight} />}
          </div>
          <PBODY.SEMIBOLD>{data?.plan?.name} </PBODY.SEMIBOLD>
          <PBODY.REGULAR style={{ marginLeft: 3 }}>
            {" - "}
            {data.plan.type === "rides" && (
              <>
                Disp:{" "}
                <span style={{ fontWeight: "600" }}>
                  {data?.remainingRides} viajes
                </span>{" "}
                -{" "}
              </>
            )}
            Vence:
            <span style={{ fontWeight: "600" }}>
              {" "}
              {moment(data?.expirationDate).format("DD/MM")}
            </span>
          </PBODY.REGULAR>
        </div>
        <IoTrashOutline
          size={20}
          onClick={() => {
            setDeleted(true);
            setPlan(data, index);
          }}
          className={styles.delete}
          style={{ color: "#ec8686", marginLeft: -1 }}
        />
      </div>
    );
  };

  return (
    <>
      <div
        onClick={() => {
          setModalPasses(true);
        }}
        className={styles.assignPass}
      >
        <PBODY.SEMIBOLD>Ver pases</PBODY.SEMIBOLD>
      </div>
      <Modal
        show={modalPasses}
        fullscreen={"md-down"}
        centered
        onEntering={() => {
          setTimeout(() => {
            dispatch(getActivePassesUser(props.data)).then((plans) => {
              setFinishLoading(true);
            });
          }, 400);
        }}
        onHide={handleClose}
        size={"lg"}
        contentClassName={styles.content}
        dialogClassName={styles.dialog}
      >
        <Modal.Header closeButton>
          <Modal.Title>Administración de pases</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.body}>
          <div>Pases Activos</div>
          <div className={finishLoading ? styles.activePlans : styles.loader}>
            <div className={styles.plans2}>
              {activePlans?.map((data, index) => {
                return <ActivePlan data={data} index={index} key={index} />;
              })}
            </div>
            {!finishLoading && (
              <Spinner variant={"primary"} size={"md"} animation="border" />
            )}
            {finishLoading && activePlans?.length < 1 && (
              <div className={styles.loader}>
                <p>Usuario no tiene pases asignados</p>
              </div>
            )}
          </div>
          <div>Pases Disponibles</div>
          <div className={styles.plans}>
            {plans?.map((plan, index) => {
              if (plan.type === "rides") {
                return <Plan key={index} index={index} plan={plan} />;
              }
            })}
          </div>
          <div>Suscripciones Disponibles</div>
          <div className={styles.plans}>
            {plans?.map((plan, index) => {
              if (plan.type === "subscription") {
                return <Plan key={index} index={index} plan={plan} />;
              }
            })}
          </div>
          <div className={styles.buttons}>
            <Button
              style={{
                backgroundColor: "transparent",
                width: 200,
                marginRight: 20,
              }}
              onPress={handleClose}
              title={"Cancelar"}
              titleColor={colors.purpleLight}
            />
            <Button
              style={{ width: 200 }}
              disabled={selectedPlan === {} && plansToDelete?.length === 0}
              title={"Confirmar"}
              onPress={() => {
                confirmPlans();
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PassesModal;
