import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Img from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import ReactGA from "react-ga4";
import { useForm } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import images from "../../../Assets/Images";
import AuthCard from "../../../Components/AuthCard";
import PTitle from "../../../Components/Text/PTitle";
import AppLayout from "../../../Containers/AppLayout";
import { signInCompany } from "../../../Store/Actions";
import styles from "./styles.module.css";

const LoginAdmin = (props) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [pin, setPin] = useState("");
  const [sendCode, setSendCode] = useState(false);
  const codeInputLogin = useSelector((state) => state.ui.codeInputLogin);
  const phoneError = useSelector((state) => state.ui.phoneError);
  const codeError = useSelector((state) => state.ui.codeError);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    getValues,
  } = useForm();
  const onSubmit = (data, e) => {
    dispatch(signInCompany(data));
  };

  const submitErrorHandler = (errors, e) => console.log(errors);

  useEffect(() => {
    setDisabled(true);
  }, [codeInputLogin]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/loginAdmin" });
  }, [ReactGA]);

  useEffect(() => {
    const x = phone.replace(code, "");
    if (x.length === 10) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [phone]);

  return (
    <AppLayout navBar={false} fluid noAuth>
      <div className={styles.hole}>
        {/* <CircleTop /> */}
        <Container fluid>
          <Row className="justify-content-center">
            <Col
              style={{
                padding: 0,
                marginBottom: 50,
                marginTop: 50,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
              sm="auto"
              md="auto"
              xs="auto"
              lg="auto"
            >
              <Img
                style={{
                  maxHeight: 60,
                  alignSelf: "center",
                  marginBottom: 25,
                }}
                src={images.laWawaPurple}
              />
              <form onSubmit={handleSubmit(onSubmit, submitErrorHandler)}>
                <AuthCard
                  title={"Sesión empresarial"}
                  subTitle1={"Ingresa tus credenciales"}
                  subTitle2={"de administrador"}
                  // message={"Ingresa tus credenciales de administrador"}
                  buttonTitle={"Continuar"}
                  questions
                  admin
                  isForm
                >
                  <Row>
                    <div className={"d-flex"}>
                      <PTitle.SEMIBOLD>Correo electrónico </PTitle.SEMIBOLD>
                      <PTitle.SEMIBOLD className={styles.required}>
                        *
                      </PTitle.SEMIBOLD>
                    </div>

                    <input
                      className={styles.input}
                      type="email"
                      autoComplete="off"
                      autoCorrect="off"
                      {...register("email", {
                        required: true,
                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                      required
                    />
                    <span
                      className={
                        errors.email ? styles.errors : styles.errorsHidden
                      }
                    >
                      Este campo es requerido
                    </span>
                  </Row>
                  <Row>
                    <div className={"d-flex"}>
                      <PTitle.SEMIBOLD>Contraseña </PTitle.SEMIBOLD>
                      <PTitle.SEMIBOLD className={styles.required}>
                        *
                      </PTitle.SEMIBOLD>
                    </div>

                    <input
                      className={styles.input}
                      type="password"
                      autoComplete="off"
                      autoCorrect="off"
                      {...register("password", {
                        required: true,
                      })}
                      required
                    />
                    <span
                      className={
                        errors.email ? styles.errors : styles.errorsHidden
                      }
                    >
                      Este campo es requerido
                    </span>
                  </Row>
                  <input
                    className={styles.button}
                    value={"Continuar"}
                    type="submit"
                  />
                </AuthCard>
              </form>
            </Col>
          </Row>
        </Container>
        {/* <CircleBottom /> */}
      </div>
    </AppLayout>
  );
};

export default LoginAdmin;
