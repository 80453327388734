
import { REMOVE_PAYMENTS, SET_PAYMENTS, SET_RATE } from "../ActionTypes";
import { fetchPayments, fetchPromoCode } from "../../Config/Services/Payments";

export const setPayments = (data) => {
  return {
    type: SET_PAYMENTS,
    data: data,
  };
};

export const getPayments = () => {
  return (dispatch) => {
    dispatch(fetchPayments());
  };
};

export const setRate = (rate) => {
  return {
    type: SET_RATE,
    data: rate,
  };
};

export const removePayments = () => {
  return {
    type: REMOVE_PAYMENTS,
  }}

export const applyPromo = (data) => {
  return (dispatch) => {
    dispatch(fetchPromoCode(data));
  };
};
