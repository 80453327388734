import React from "react";
import styles from "./styles.module.css";

const TITLEBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.TITLEBOLD].join(" ")}>
    {children}
  </div>
);
const TITLESEMIBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H1SEMIBOLD].join(" ")}>
    {children}
  </div>
);
const TITLEREGULAR = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H1REGULAR].join(" ")}>
    {children}
  </div>
);

const H1 = {
  BOLD: ({ style, className, children }) =>
    TITLEBOLD({ style, className, children }),
  SEMIBOLD: ({ style, className, children }) =>
    TITLESEMIBOLD({ style, className, children }),
  REGULAR: ({ style, className, children }) =>
    TITLEREGULAR({ style, className, children }),
};

export default H1;
