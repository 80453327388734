import { REMOVE_PAYMENTS, SET_PAYMENTS, SET_RATE } from "../ActionTypes";

const initialState = {
  paymentsArray: null,
  rate: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENTS:
      return {
        ...state,
        paymentsArray: action.data,
      };
    case SET_RATE:
      return {
        ...state,
        rate: action.data,
      };
    case REMOVE_PAYMENTS:
      return {
        ...state,
        paymentsArray: null,
      };
    default:
      return state;
  }
};
export default reducer;
