import React from "react";
import { IoIosLogOut, IoMdArrowBack } from "react-icons/io";
import Routing from "../../Config/Routing";
import NavigationItem from "../NavigationItem";
import PCAPTION from "../Text/PTitle";
import styles from "./styles.module.css";

const navigationItems = ({
  access,
  logout,
  auth,
  goToLogin,
  noAuth,
  action,
  banners,
  uiState,
  modalTrigger,
  type,
  user,
}) => {
  return (
    <div className={styles.NavigationItems}>
      {Routing(access, type).map((item, i) => {
        if (item.type == "credits") {
          return (
            <div key={i} className={styles.myItem}>
              <span style={{ fontWeight: "normal" }}>{item.text} </span>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(user.company.balance)}
            </div>
          );
        }
        if (item.type == "company") {
          return (
            <div key={i} className={styles.myItem}>
              <span style={{ fontWeight: "normal" }}>{item.text} </span>{" "}
              {user.company.businessName}
            </div>
          );
        }
        if (item?.type === "promo") {
          return (
            <div onClick={modalTrigger} className={styles.NavigationItem}>
              {item.text}
            </div>
          );
        }

        if (!item.link) {
          return (
            <div key={i}>
              {!noAuth && (
                <>
                  {auth && (
                    <>
                      <div onClick={goToLogin} className={styles.tooltip2}>
                        <IoMdArrowBack color={"white"} size={25} />
                        <PCAPTION.REGULAR
                          style={{ color: "white", marginLeft: 5 }}
                        >
                          ¿Ya tienes una cuenta?
                        </PCAPTION.REGULAR>
                      </div>
                      <div onClick={goToLogin} className={styles.logout}>
                        ¿Ya tienes una cuenta?
                      </div>
                    </>
                  )}
                  {!auth && (
                    <>
                      <div onClick={logout} className={styles.tooltip}>
                        <IoIosLogOut color={"white"} size={25} />
                        <span className={styles.tooltiptext}>
                          Cerrar sesión
                        </span>
                      </div>
                      <div onClick={logout} className={styles.logout}>
                        Cerrar sesión
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          );
        } else {
          return (
            <div key={i}>
              <NavigationItem action={item.action} exact link={item.link}>
                {item.text}
              </NavigationItem>
            </div>
          );
        }
      })}
    </div>
  );
};

export default navigationItems;
