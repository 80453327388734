const noAccess = [
  {
    text: "Cerrar sesión",
    exact: true,
  },
];

const admin = [
  {
    text: "Empresa:",
    type: "company",
  },
  {
    text: "Saldo disponible:",
    type: "credits",
  },
  {
    text: "Cerrar sesión",
    exact: true,
  },
];

const client = [
  {
    link: "/",
    text: "Inicio",
    exact: true,
  },
  {
    link: "/pases",
    text: "Compra tu pase",
    exact: true,
    action: true,
  },
  {
    type: "promo",
    text: "Canjea tu código",
  },
  {
    text: "Cerrar sesión",
    exact: true,
  },
];

const withAccess = [];

const Routing = (access, type) => {
  if (type === "Admin") {
    return admin;
  }
  if (access) {
    if (type === "Client") return client;
  } else {
    return noAccess;
  }
};

export default Routing;
