import moment from "moment";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import Profile from "../../../Components/Profile";
import H4 from "../../../Components/Text/H4";
import AppLayout from "../../../Containers/AppLayout";
import { getPayments } from "../../../Store/Actions";
import styles from "./styles.module.css";

const Home = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const payments = useSelector((state) => state.payments.paymentsArray);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (payments?.length) {
      const auxProducts = payments.map((item, i) => {
        let product = {
          id: item._id,
          date: item.createdAt,
          type: item.plan.type === "rides" ? "Viaje" : "Suscripción",
          detail: {
            amount:
              item.plan.type === "rides"
                ? `${item.plan.amountRides}`
                : `${item.plan.amountDays}`,
            type:
              item.plan.type === "rides"
                ? `${item.plan.amountRides === 1 ? "Viaje" : "Viajes"}`
                : `${item.plan.amountDays === 1 ? "Día" : "Días"}`,
          },
          price: item.plan.cost,
          form: item.type === "paypal" ? "PayPal" : "Ubii Pagos",
        };
        return product;
      });
      setProducts(auxProducts);
    }
  }, [payments]);

  useEffect(() => {
    dispatch(getPayments());
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/home" });
  }, []);

  const columns = [
    {
      dataField: "_id",
      text: "Fecha",
      hidden: true,
      style: {
        border: "none",
      },
      type: "string",
    },
    {
      dataField: "date",
      text: "Fecha",
      sort: true,
      style: {
        border: "none",
      },
      type: "date",
      formatter: (cell) => {
        return moment(cell).local().format("D[/]MM[/]YYYY");
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          return Date.parse(a) - Date.parse(b);
        } else if (order === "desc") {
          return Date.parse(b) - Date.parse(a);
        }
      },
    },
    {
      dataField: "type",
      text: "Tipo",
      sort: true,
      style: {
        border: "none",
      },
      type: "string",
    },
    {
      dataField: "detail",
      text: "Detalle",
      sort: true,
      style: {
        border: "none",
      },
      formatter: (cell) => {
        return (
          <div>
            {cell.amount} {cell.type}
          </div>
        );
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          return a.amount - b.amount;
        } else if (order === "desc") {
          return b.amount - a.amount;
        }
      },
      type: "string",
      // headerStyle: { display: "flex", alignItems: "center" },
      // sortCaret: (order, column) => {
      //   if (!order)
      //     return (
      //       <span style={{ display: "flex", flexDirection: "row" }}>
      //         <div className={styles.upArrow}></div>
      //         <div className={styles.downArrow}></div>
      //       </span>
      //     );
      //   else if (order === "asc")
      //     return (
      //       <span style={{ display: "flex", flexDirection: "row" }}>
      //         <div className={styles.upArrow}></div>
      //         <div className={styles.downArrow}></div>
      //       </span>
      //     );
      //   else if (order === "desc")
      //     return (
      //       <span style={{ display: "flex", flexDirection: "row" }}>
      //         <div className={styles.upArrow}></div>
      //         <div className={styles.downArrow}></div>
      //       </span>
      //     );
      //   return null;
      // },
    },
    {
      dataField: "price",
      text: "Monto",
      sort: true,
      style: {
        border: "none",
      },
      type: "number",
      formatter: (cell) => {
        return <div>${cell}</div>;
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          return a - b;
        } else if (order === "desc") {
          return b - a;
        }
      },
    },
    {
      dataField: "form",
      text: "Forma de pago",
      sort: true,
      style: {
        border: "none",
      },
      type: "string",
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;
  const options = {
    onSizePerPageChange: (sizePerPage, page) => {},
    onPageChange: (page, sizePerPage) => {},
  };

  return (
    <AppLayout sideBar>
      <div className={styles.homeWrapper}>
        <Profile />
        <H4.BOLD style={{ marginTop: 35 }}>Historial de pagos</H4.BOLD>

        <ToolkitProvider
          keyField="_id"
          data={products}
          columns={columns}
          search
        >
          {(props) => (
            <div className={styles.test}>
              <div className={styles.search}>
                <SearchBar
                  srText={null}
                  placeholder={"Buscar"}
                  {...props.searchProps}
                />
              </div>
              <BootstrapTable
                {...props.baseProps}
                keyField={"_id"}
                hover
                sort={{
                  order: "desc",
                  dataField: "date",
                }}
                pagination={paginationFactory(options)}
                rowStyle={{ border: "none" }}
                wrapperClasses="table-responsive"
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </AppLayout>
  );
};

export default React.memo(Home);
