import {
  removeUser,
  setPlans,
  setRate,
  updateUser,
} from "../../../Store/Actions";
import wawaAPI, * as API_ROUTES from "../api";

export const fetchPlans = (data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.get(API_ROUTES.PLANS, { headers });
      if (response.status === 200) {
        dispatch(setPlans(response.data.plans));
        dispatch(setRate(response.data.rate));
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchProfile = () => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.get(API_ROUTES.PROFILE, { headers });
      if (response.status === 200) {
        dispatch(updateUser(response.data.user));
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchProfileAdmin = () => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.get(API_ROUTES.COMPANY_USER_PROFILE, {
        headers,
      });
      if (response.status === 200) {
        dispatch(updateUser(response.data));
        // console.log("data del user", response.data);
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
    }
  };
};
