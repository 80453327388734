import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.css";

const SideBarItem = ({ link, title, icon, isCollapsed }) => {
  const [active, setActive] = useState(false);

  return (
    <div className={styles.link}>
      <NavLink
        exact
        className={styles.unactive}
        activeClassName={styles.activeLink}
        to={link}
      >
        <div className={styles.margin}>
          {icon}
          <span className={isCollapsed ? styles.titleHidden : styles.titleShow}>
            {" "}
            {title}
          </span>
        </div>
      </NavLink>
    </div>
  );
};

export default SideBarItem;
