import {
  enableCodeInput,
  enableErrorPhone,
  setBadCodeEntry,
  setErrorRegister,
  setFailedRegister,
  setSuccessRegister,
  setUser,
  startAuthLoading,
  stopAuthLoading,
} from "../../../Store/Actions";
import wawaAPI, * as API_ROUTES from "../api";

export const fetchSms = (data) => {
  return (dispatch) => {
    dispatch(startAuthLoading());
    setTimeout(() => {
      wawaAPI
        .post(API_ROUTES.SMS_CODE, {
          phone: data,
        })
        .then((response) => {
          dispatch(stopAuthLoading());
          if (response.data.success) {
            dispatch(enableCodeInput());
          } else if (!response.data.success) {
            dispatch(enableErrorPhone());
          }
        })
        .catch((err) => {
          dispatch(stopAuthLoading());
        });
    }, 1500);
  };
};

export const fetchSmsCode = (data) => {
  return (dispatch) => {
    dispatch(startAuthLoading());
    setTimeout(() => {
      wawaAPI
        .post(API_ROUTES.SMS_VERIFY, { ...data })
        .then((response) => {
          dispatch(stopAuthLoading());
          if (response.data.user) {
            dispatch(setUser(response.data.user));
          } else {
            dispatch(setBadCodeEntry());
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(stopAuthLoading());
        });
    }, 1500);
  };
};

export const companyRequest = (data) => {
  return (dispatch) => {
    dispatch(startAuthLoading());
    setTimeout(() => {
      wawaAPI
        .post(API_ROUTES.COMPANY_SIGNUP, data)
        .then((response) => {
          dispatch(stopAuthLoading());
          if (response.status === 200) {
            dispatch(setSuccessRegister());
          } else {
            dispatch(setFailedRegister());
          }
        })
        .catch((err) => {
          dispatch(setErrorRegister());
          console.log(err);
        });
    });
  };
};

export const signUpClient = (data) => {
  return (dispatch) => {
    dispatch(startAuthLoading());
    setTimeout(() => {
      wawaAPI
        .post(API_ROUTES.SIGN_UP_WEB, data)
        .then((response) => {
          dispatch(stopAuthLoading());
          if (response.status === 200) {
            if (response.data.user) {
              dispatch(setUser(response.data.user));
            }
            dispatch(setSuccessRegister());
          } else {
            dispatch(setFailedRegister());
          }
        })
        .catch((err) => {
          dispatch(setErrorRegister());
          console.log(err);
          if (err.response.status === 404) {
          }
        });
    }, 1500);
  };
};

export const fetchSignUpCode = (data) => {
  return (dispatch) => {
    dispatch(startAuthLoading());
    setTimeout(() => {
      wawaAPI
        .post(API_ROUTES.SEND_CODE, { phone: data })
        .then((response) => {
          dispatch(stopAuthLoading());
          if (response.status === 200) {
            dispatch(enableCodeInput());
          } else {
            dispatch(enableErrorPhone());
          }
        })
        .catch((err) => {
          dispatch(stopAuthLoading());
          console.log(err);
        });
    });
  };
};

export const fetchSignInCompany = (data) => {
  return (dispatch) => {
    dispatch(startAuthLoading());
    setTimeout(() => {
      wawaAPI
        .post(API_ROUTES.COMPANY_LOGIN, data)
        .then((response) => {
          dispatch(stopAuthLoading());
          if (response.status === 200) {
            dispatch(setUser(response.data.user));
          } else {
            // dispatch(enableErrorPhone());
          }
        })
        .catch((err) => {
          dispatch(stopAuthLoading());
          console.log(err);
        });
    });
  };
};
