import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Modal from "react-bootstrap/Modal";
import { IoAddOutline, IoTrashOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../Components/Button";
import PassesModal from "../../../Components/PassesModal";
import H4 from "../../../Components/Text/H4";
import AppLayout from "../../../Containers/AppLayout";
import {
  deleteUser,
  getCompanyEmployees,
  hideUsersModal,
  showUsersModal,
} from "../../../Store/Actions";
import Colors from "../../../Constants/colors";
import { getCompanyMovements } from "../../../Store/Actions/admin";
import AdminAddUsers from "../AdminAddUsers";
import styles from "./styles.module.css";
import moment from "moment";
import colors from "ansicolors";
const AdminWalletMovements = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const companyMovements = useSelector((state) => state.admin.companyMovements);
  const [movements, setMovements] = useState([]);
  const employeesModal = useSelector((state) => state.ui.employeesModal);

  useEffect(() => {
    dispatch(getCompanyMovements());
  }, []);

  useEffect(() => {
    if (companyMovements?.length) {
      const auxMovements = companyMovements.map((item, i) => {
        let movement = {
          _id: item._id,
          type: item.type === "deposit" ? "Recarga" : "Asignación",
          amount: {
            type: item.type,
            value: item.amount,
          },
          // item.type === "deposit" ? "+ $" + item.amount : "- $" + item.amount,
          employee:
            item.type === "allocation"
              ? item?.allocation?.employee?.firstName +
                " " +
                item?.allocation?.employee?.lastName
              : "",
          description:
            item.type === "allocation"
              ? "Plan " + item?.allocation?.plan?.name + " asignado"
              : "Recarga de saldo",
          date: item.createdAt,
        };
        return movement;
      });
      setMovements(auxMovements);
    }
  }, [companyMovements]);

  const columns = [
    {
      dataField: "_id",
      text: "Id",
      sort: true,
      style: {
        border: "none",
      },
      type: "string",
      hidden: true,
    },
    {
      dataField: "date",
      text: "Fecha",
      sort: true,
      style: {
        border: "none",
      },
      type: "date",
      formatter: (cell) => {
        return moment(cell).local().format("D[/]MM[/]YYYY");
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          return Date.parse(a) - Date.parse(b);
        } else if (order === "desc") {
          return Date.parse(b) - Date.parse(a);
        }
      },
    },
    {
      dataField: "type",
      text: "Tipo",
      style: {
        border: "none",
      },
      type: "string",
    },
    {
      dataField: "employee",
      text: "Empleado",
      style: {
        border: "none",
      },
      type: "string",
    },
    {
      dataField: "description",
      text: "Descripción",
      style: {
        border: "none",
      },
      type: "string",
    },
    {
      dataField: "amount",
      text: "Monto",
      sort: true,
      style: {
        border: "none",
      },
      type: "string",
      formatter: (cell) => {
        return (
          <div
            style={{
              color: cell.type === "deposit" ? Colors.green : Colors.red,
              fontWeight: "bold",
            }}
          >
            {cell.type === "deposit" ? "+ $" + cell.value : "- $" + cell.value}
          </div>
        );
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") {
          return a.value - b.value;
        } else if (order === "desc") {
          return b.value - a.value;
        }
      },
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;
  const options = {
    onSizePerPageChange: (sizePerPage, page) => {},
    onPageChange: (page, sizePerPage) => {},
  };

  return (
    <AppLayout sideBar>
      <div className={styles.homeWrapper}>
        <div className={styles.header}>
          <H4.BOLD style={{ marginTop: 35 }}>Lista de Movimientos</H4.BOLD>
        </div>
        <ToolkitProvider
          keyField="_id"
          data={movements}
          columns={columns}
          search
        >
          {(props) => (
            <div className={styles.tableStyle}>
              <div className={styles.search}>
                <SearchBar
                  srText={null}
                  placeholder={"Buscar"}
                  {...props.searchProps}
                />
              </div>
              <BootstrapTable
                // remote
                noDataIndication={"No hay datos"}
                {...props.baseProps}
                keyField={"_id"}
                hover
                sort={{
                  order: "desc",
                  dataField: "date",
                }}
                pagination={paginationFactory(options)}
                rowStyle={{ border: "none" }}
                wrapperClasses="table-responsive"
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </AppLayout>
  );
};

export default React.memo(AdminWalletMovements);
