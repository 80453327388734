import { FUNDING, PayPalButtons } from "@paypal/react-paypal-js";
import {
  closeUbii as closeUbiiDev,
  initUbii as initUbiiDev,
} from "@ubiipagos/boton-ubii-dc";
import { closeUbii, initUbii } from "@ubiipagos/boton-ubii";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import Row from "react-bootstrap/Row";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import images from "../../../Assets/Images";
import Button from "../../../Components/Button";
import Package from "../../../Components/Package";
import H1 from "../../../Components/Text/H1";
import H5 from "../../../Components/Text/H5";
import H6 from "../../../Components/Text/H6";
import PBODY from "../../../Components/Text/Pbody";
import PTITLE from "../../../Components/Text/Title";
import {
  submitPaypalPayment,
  submitUbiiPayment,
} from "../../../Config/Services/Payments";
import colors from "../../../Constants/colors";
import AppLayout from "../../../Containers/AppLayout";
import { getPlans } from "../../../Store/Actions";
import { startPaymentSaving } from "../../../Store/Actions/ui";
import styles from "./styles.module.css";

const modals = [
  {
    image: images.paymentVerified,
    title: "Pago Exitoso",
    message:
      "Tu pago fue realizado exitosamente, puedes disfrutar de la Wawa cuando desees.",
  },
  {
    image: images.paymentInProgress,
    title: "Su pago se encuentra en verificación",
    message:
      "En lo que validemos tu pago, recibirás una notificación y podrás disfrutar de La Wawa.",
  },
  {
    image: images.paymentFailed,
    title: "Pago rechazado",
    message:
      "Tu pago fue rechazado ya que no pudimos validarlo. Comunícate con nosotros a través de nuestro formulario de contacto",
  },
];

const Payments = (props) => {
  const dispatch = useDispatch();
  const [modalShown, setModal] = useState(false);
  const [fullscreen, setFullscreen] = useState("true");
  const paymentSaving = useSelector((state) => state.ui.paymentSaving);
  const paymentSaved = useSelector((state) => state.ui.paymentSaved);
  const plans = useSelector((state) => state.plans.plans);
  const [selectedPackage, setSelectedPackage] = useState("");
  const history = useHistory();
  const [orderId, setOrderId] = useState(
    Math.floor(Math.random() * (1000000 - 0 + 1)) + 0
  );
  const [ubiiClicked, setUbiiClicked] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [activeTab, setActiveTab] = useState("trips");
  const [paymentEnabled, setPaymentEnabled] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const callbackFn = (answer) => {
    console.log(answer);
    if (answer.error) {
      //Aquí va su lógica para manejar errores. Ejm:
      console.log("el error fue:");
      console.log(answer.data);
      setModalInfo(modals[2]);
      setModal(true);
    } else {
      if (answer.data.R === "0") {
        setModalInfo(modals[0]);
        setModal(true);
        submitPagoUbii(answer.data.orderID);
      } else {
        setModalInfo(modals[2]);
        setModal(true);
      }
    }
  };

  useEffect(() => {
    dispatch(getPlans());
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/pases" });
  }, []);

  const submitPagoUbii = (orderID) => {
    const data = {
      plan: selectedPackage,
      orderID,
    };
    dispatch(startPaymentSaving());
    dispatch(submitUbiiPayment(data));
  };

  const submitPagoPaypal = (orderID) => {
    const data = {
      plan: selectedPackage,
      orderID,
    };
    dispatch(startPaymentSaving());
    dispatch(submitPaypalPayment(data));
  };

  const setTrips = () => {
    setActiveTab("trips");
  };

  const setSubscriptions = () => {
    setActiveTab("subscriptions");
  };

  const selectPackage = (item) => {
    setSelectedPackage(item);
  };

  const handleShow = (breakpoint) => {
    setFullscreen(breakpoint);
    setModal(true);
  };

  useEffect(() => {
    if (confirmed) {
      if (process.env.NODE_ENV === "development") {
        initUbiiDev(
          "ubiiboton",
          {
            amount_ds: selectedPackage?.cost,
            amount_bs: selectedPackage?.costBs,
            concept: "Pago de viaje",
            principal: "bs",
            clientId: process.env.REACT_APP_UBII_CLIENT_ID,
            orderId: orderId.toString(),
          },
          callbackFn,
          {
            text: "Pagar",
          }
        );
      } else {
        initUbii(
          "ubiiboton",
          {
            amount_ds: selectedPackage?.cost,
            amount_bs: selectedPackage?.costBs,
            concept: "Pago de viaje",
            principal: "bs",
            clientId: process.env.REACT_APP_UBII_CLIENT_ID,
            orderId: orderId.toString(),
          },
          callbackFn,
          {
            text: "Pagar",
          }
        );
      }
    }
  }, [confirmed]);

  const confirmAndContinue = () => {
    setConfirmed(true);
    setPaymentEnabled(true);
  };

  const cancelAndContinue = () => {
    setConfirmed(false);
    setPaymentEnabled(false);
    if (ubiiClicked) {
      if (process.env.NODE_ENV === "development") {
        closeUbiiDev();
      } else {
        closeUbii();
      }
    }
  };

  const setUbii = () => {
    setUbiiClicked(true);
  };

  const closeModal = () => {
    setModal(false);
    history.replace("/");
  };

  return (
    <AppLayout
      // sideBar
      fluid
      background
    >
      {plans?.length >= 1 && (
        <Row
          style={{
            marginLeft: 0,
          }}
          className={"justify-content-evenly"}
        >
          <Col
            style={{ paddingTop: "10vh" }}
            className={"justify-content-center align-items-center"}
            xs="12"
            sm="12"
            md="8"
            lg="5"
            xl="5"
          >
            <H1.BOLD>Personaliza tus viajes</H1.BOLD>
            <PTITLE.REGULAR style={{ marginTop: 15, marginBottom: 10 }}>
              Elige el plan de tu preferencia y por último el método de pago que
              prefieras.
            </PTITLE.REGULAR>
            <H5.SEMIBOLD style={{ marginTop: 15, marginBottom: 10 }}>
              Selecciona el pase de tu preferencia:
            </H5.SEMIBOLD>
            <Form color={colors.purpleDark}>
              <div style={{ marginTop: 15 }} className={styles.packages}>
                <div className={styles.tabs}>
                  <div
                    onClick={() => setTrips()}
                    style={{
                      backgroundColor:
                        activeTab === "trips" && colors.purpleLight,
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: colors.purpleLight,
                      borderTopLeftRadius: 10,
                    }}
                    className={styles.tab}
                  >
                    <H5.BOLD
                      style={
                        activeTab === "trips"
                          ? { color: "white" }
                          : { color: colors.purpleLight }
                      }
                    >
                      Viajes
                    </H5.BOLD>
                  </div>
                  <div
                    onClick={() => setSubscriptions()}
                    style={{
                      backgroundColor:
                        activeTab === "subscriptions" && colors.purpleLight,
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: colors.purpleLight,
                      borderTopRightRadius: 10,
                    }}
                    className={styles.tab}
                  >
                    <H5.BOLD
                      style={
                        activeTab === "subscriptions"
                          ? { color: "white" }
                          : { color: colors.purpleLight }
                      }
                    >
                      Suscripciones
                    </H5.BOLD>
                  </div>
                </div>
                {activeTab === "subscriptions" && plans && (
                  <>
                    {plans
                      .filter((item) => item.type === "subscription")
                      .map((item, i) => {
                        return (
                          <Package
                            onPress={() => selectPackage(item)}
                            item={item}
                            cost={item.cost}
                            name={item.name}
                            key={i}
                            selected={item._id === selectedPackage._id}
                            disabled={confirmed}
                          />
                        );
                      })}
                  </>
                )}
                {activeTab === "trips" && plans && (
                  <>
                    {plans
                      .filter((item) => item.type === "rides")
                      .map((item, i) => {
                        return (
                          <Package
                            onPress={() => selectPackage(item)}
                            item={item}
                            cost={item.cost}
                            name={item.name}
                            key={i}
                            selected={item._id === selectedPackage._id}
                            disabled={confirmed}
                          />
                        );
                      })}
                  </>
                )}
              </div>
            </Form>
            <Col className={"justify-content-center align-items-center"}>
              <div className={styles.continueBack}>
                {confirmed && (
                  <Button
                    title={"Elegir otro plan"}
                    onPress={cancelAndContinue}
                    style={{
                      marginTop: 20,
                      width: 300,
                      backgroundColor: colors.purpleDark,
                      color: "white",
                    }}
                  />
                )}
                {!confirmed && (
                  <Button
                    title={"Confirmar selección"}
                    onPress={confirmAndContinue}
                    disabled={selectedPackage === ""}
                    style={{
                      marginTop: 20,
                      width: 300,
                      backgroundColor:
                        selectedPackage === "" ? "#B0B0B0" : colors.purpleDark,
                    }}
                  />
                )}
              </div>
            </Col>
          </Col>
          <Col
            style={{ paddingTop: "10vh" }}
            className={` justify-content-center ${styles.paymentsSide}`}
            xs="12"
            sm="12"
            md="8"
            lg="5"
            xl="5"
          >
            <div className={styles.ubiiWrapper}>
              <H5.SEMIBOLD
                style={{
                  color: "#9570FC",
                  textAlign: "center",
                  marginBottom: 10,
                }}
              >
                Elige el método de pago de tu preferencia
              </H5.SEMIBOLD>
              <H6.SEMIBOLD style={{ textAlign: "center" }}>
                Paga mediante Pago Móvil, Tarjeta de Débito en bolívares y Zelle
                a través de UbiiPagos:
              </H6.SEMIBOLD>
              <div style={{ marginTop: 25 }}>
                {!confirmed && <div className={styles.fakebutton}>Pagar</div>}
                {confirmed && (
                  <div onClick={setUbii}>
                    <button id={"ubiiboton"}></button>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.separator}>
              <div className={styles.line}></div>
              <PBODY.REGULAR className={styles.separatorText}>
                o paga en dólares utilizando
              </PBODY.REGULAR>
              <div className={styles.line}></div>
            </div>

            <>
              <PayPalButtons
                style={{ shape: "pill" }}
                forceReRender={selectedPackage}
                disabled={paymentEnabled === false}
                fundingSource={FUNDING.PAYPAL}
                onApprove={(data, actions) => {
                  return actions.order.capture().then(function (details) {
                    setModalInfo(modals[0]);
                    submitPagoPaypal(details.id);
                    setModal(true);
                    // This function shows a transaction success message to your buyer.
                  });
                }}
                onError={(err) => {
                  setModalInfo(modals[2]);
                }}
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: selectedPackage.cost,
                        },
                        reference_id: "1",
                      },
                    ],
                    application_context: {
                      shipping_preference: "NO_SHIPPING",
                    },
                  });
                }}
              />
              <div style={{ height: 5 }}></div>
              <PayPalButtons
                style={{ shape: "pill" }}
                disabled={paymentEnabled === false}
                forceReRender={selectedPackage}
                fundingSource={FUNDING.CARD}
                forceReRender={selectedPackage}
                onApprove={(data, actions) => {
                  return actions.order.capture().then(function (details) {
                    setModalInfo(modals[0]);
                    submitPagoPaypal(details.id);
                    setModal(true);
                    // This function shows a transaction success message to your buyer.
                  });
                }}
                onError={(err) => {
                  console.log("error", err);
                  setModalInfo(modals[2]);
                  setModal(true);
                }}
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: selectedPackage.cost,
                        },
                        reference_id: "1",
                      },
                    ],
                    application_context: {
                      shipping_preference: "NO_SHIPPING",
                    },
                  });
                }}
              />
            </>
          </Col>
        </Row>
      )}
      <Modal
        fullscreen={true}
        animation={true}
        centered={true}
        show={modalShown}
      >
        <ModalBody className={styles.modal} style={{}}>
          <Image style={{ width: 250 }} src={images.logoPurple} />
          <Image
            style={{
              width: 100,
              marginBottom: 15,
              marginTop: 80,
            }}
            src={modalInfo.image}
          />
          <H6.BOLD style={{ marginTop: 15, marginBottom: 10 }}>
            {modalInfo.title}
          </H6.BOLD>
          <PBODY.BOLD
            style={{
              fontSize: 16,
              maxWidth: 400,
              textAlign: "center",
              color: "#1A0063",
            }}
          >
            {modalInfo.message}
          </PBODY.BOLD>
          <Button
            onPress={closeModal}
            style={{ width: 400, marginTop: 60 }}
            title={"Cerrar"}
          />
        </ModalBody>
      </Modal>
    </AppLayout>
  );
};

export default Payments;
