import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Components/Button";
import Pcaption from "../../Components/Text/Pcaption";
import {
  hidePromoModal,
  startFetchingPromo,
  applyPromo,
} from "../../Store/Actions";
import styles from "./styles.module.css";

const PromoModal = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const ui = useSelector((state) => state.ui);
  const [show, setShow] = useState(false);
  const [toast, setToast] = useState(false);
  const fetchingPromo = useSelector((state) => state.ui.fetchingPromo);
  const promoValid = useSelector((state) => state.ui.promoValid);
  const [value, setValue] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    getValues,
  } = useForm();

  const onSubmit = (data, e) => {
    setShow(true);
    dispatch(startFetchingPromo());
    dispatch(applyPromo(data));
  };

  useEffect(() => {
    if (ui.promoValid && ui.finishFetchingPromo) {
      setToast(true);
      setShow(false);
      dispatch(hidePromoModal());
    }
  }, [ui.promoValid, ui.finishFetchingPromo]);

  const submitErrorHandler = (errors, e) => console.log(errors);

  return (
    <>
      <Modal
        show={ui?.promoModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={"md-down"}
        onHide={() => {
          dispatch(hidePromoModal());
          setShow(false);
          setValue("");
        }}
        onExited={() => {
          setValue("");
        }}
        dialogClassName={styles.promoModal}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Aplica tu código promocional
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className={styles.body}
            onSubmit={handleSubmit(onSubmit, submitErrorHandler)}
          >
            <input
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              placeholder="Código promocional"
              {...register("code", {
                required: true,
              })}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              type="text"
              value={value}
              defaultValue={value}
              // onBlur={() => {
              //   if (value === "") {
              //     setValue("");
              //   }
              // }}
              // onFocus={() => {
              //   if (value === "") {
              //     setValue("");
              //   }
              // }}
              className={styles.input}
            />
            <Pcaption.REGULAR
              style={{
                marginTop: 25,
                color:
                  ui.finishFetchingPromo && ui.promoValid === false && show
                    ? "red"
                    : "transparent",
              }}
            >
              Código inválido
            </Pcaption.REGULAR>
            <Button
              form
              loading={fetchingPromo}
              title={"Canjear"}
              style={{ width: 300, marginTop: 35 }}
            />
          </form>
        </Modal.Body>
      </Modal>
      <Toast
        className={styles.toast}
        onClose={() => {
          setShow(false);
          setToast(false);
        }}
        show={toast}
        delay={3500}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto">¡¡Woohoo!!</strong>
          <small>Hace 0 minutos</small>
        </Toast.Header>
        <Toast.Body>¡Tu código fue canjeado de manera correcta!</Toast.Body>
      </Toast>
    </>
  );
};

export default PromoModal;
