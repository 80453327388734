import {
  getProfileAdmin,
  removeUser,
  setCompanyEmployees,
  setCompanyRequests,
  setPlansForUser,
  setRemovePlansForUSer,
  updateCompanyBalance,
  finishPaymentSaving,
  stopPaymentSaving,
  hideUsersModal,
} from "../../../Store/Actions";
import {
  filterRequest,
  setCompanyMovements,
  setResetPassword,
} from "../../../Store/Actions/admin";
import wawaAPI, * as API_ROUTES from "../api";

export const fetchCompanyEmployees = () => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.get(API_ROUTES.COMPANY_EMPLOYEES, {
        headers,
        params: {
          company: auth.user.company._id,
        },
      });
      if (response.status === 200) {
        dispatch(setCompanyEmployees(response.data));
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchCompanyMovements = () => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.get(API_ROUTES.COMPANY_MOVEMENTS, {
        headers,
      });
      if (response.status === 200) {
        dispatch(setCompanyMovements(response.data));
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchCompanyRequests = (data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.get(API_ROUTES.COMPANY_REQUESTS, {
        headers,
        params: {
          company: auth.user.company._id,
        },
      });
      if (response.status === 200) {
        dispatch(setCompanyRequests(response.data));
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchManageRequest = (data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.patch(API_ROUTES.COMPANY_REQUESTS, data, {
        headers,
      });
      if (response.status === 200) {
        dispatch(filterRequest(data.requestID));
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
      console.log(error.request);
    }
  };
};

export const fetchUploadContacts = (data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
        "Content-Type": "multipart/form-data",
      };
      let response = await wawaAPI.post(API_ROUTES.UPLOAD_CONTACTS, data, {
        headers,
      });
      if (response.status === 200) {
        console.log(response);
        dispatch(hideUsersModal());
        dispatch(fetchCompanyEmployees());
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchCreateUser = (data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.post(API_ROUTES.CREATE_USER, data, {
        headers,
      });
      if (response.status === 200) {
        dispatch(hideUsersModal());
        dispatch(fetchCompanyEmployees());
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchResetPassword = (data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.patch(API_ROUTES.RESET_PASSWORD, data, {
        headers,
      });
      if (response.status === 200) {
        // dispatch(filterRequest(data.requestID));
        alert("Su contraseña ha sido cambiada");
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
      console.log(error.request);
    }
  };
};

export const fetchValidatePassword = (data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.post(API_ROUTES.VALIDATE_PASSWORD, data, {
        headers,
      });
      if (response.status === 200) {
        dispatch(setResetPassword());
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
      console.log(error.request);
    }
  };
};

export const fetchCompanyUbiiPayment = (data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.post(API_ROUTES.COMPANY_UBII_PAYMENT, data, {
        headers,
      });
      if (response.status === 200) {
        dispatch(finishPaymentSaving());
        dispatch(stopPaymentSaving());
        dispatch(getProfileAdmin());
      } else {
        dispatch(stopPaymentSaving());
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
      console.log(error.request);
    }
  };
};

export const fetchCompanyPayPalPayment = (data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.post(
        API_ROUTES.COMPANY_PAYPAL_PAYMENT,
        data,
        {
          headers,
        }
      );
      if (response.status === 200) {
        dispatch(finishPaymentSaving());
        dispatch(stopPaymentSaving());
        dispatch(getProfileAdmin());
      } else {
        dispatch(stopPaymentSaving());
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
      console.log(error.request);
    }
  };
};

export const fetchActivePasses = (data, callback) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const headers = {
      Authorization: "Bearer " + auth.user.token,
      authtype: "web",
    };
    return new Promise((resolve, reject) => {
      wawaAPI
        .get(API_ROUTES.USER_ACTIVE_PLANS, {
          headers: headers,
          params: {
            userID: data._id,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            dispatch(setPlansForUser(data._id, response.data));
            resolve(response.data);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
};

export const fetchAssignPass = (id, data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      return new Promise((resolve, reject) => {
        wawaAPI
          .post(
            API_ROUTES.USER_ASSIGN_PLANS,
            {
              movements: data,
            },
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.status === 200) {
              // dispatch(setPlansForUserAssigned(id, response.data));
              dispatch(getProfileAdmin());
              dispatch(updateCompanyBalance(response.data.balance));
              resolve(response.data);
            } else {
            }
            if (response.status === 401) {
              dispatch(removeUser());
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(err.request);
          });
      });
    } catch (error) {
      console.log(error);
      console.log(error.request);
    }
  };
};

export const fetchDeletePass = (id, data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      return new Promise((resolve, reject) => {
        wawaAPI
          .patch(
            API_ROUTES.USER_DELETE_ACTIVE_PLANS,
            {
              allocations: data,
            },
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.status === 200) {
              dispatch(setRemovePlansForUSer(id, data));
              resolve(response.data);
            } else {
            }
            if (response.status === 401) {
              dispatch(removeUser());
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(err.request);
          });
      });
    } catch (error) {
      console.log(error);
      console.log(error.request);
    }
  };
};

export const fetchDeleteUser = (data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.patch(API_ROUTES.DELETE_USER_PROFILE, null, {
        headers,
        params: {
          userID: data,
        },
      });
      if (response.status === 200) {
        // dispatch(fetchCompanyEmployees());
        window.location.reload();
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
      console.log(error.request);
    }
  };
};
