import React from "react";
import styles from "./styles.module.css";

const H2BOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H2BOLD].join(" ")}>
    {children}
  </div>
);
const H2SEMIBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H2SEMIBOLD].join(" ")}>
    {children}
  </div>
);
const H2REGULAR = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H2REGULAR].join(" ")}>
    {children}
  </div>
);

const H2 = {
  BOLD: ({ style, className, children }) =>
    H2BOLD({ style, className, children }),
  SEMIBOLD: ({ style, className, children }) =>
    H2SEMIBOLD({ style, className, children }),
  REGULAR: ({ style, className, children }) =>
    H2REGULAR({ style, className, children }),
};

export default H2;
