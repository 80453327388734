import React from "react";
import styles from "./styles.module.css";

const H1BOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H1BOLD].join(" ")}>
    {children}
  </div>
);
const H1SEMIBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H1SEMIBOLD].join(" ")}>
    {children}
  </div>
);
const H1REGULAR = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H1REGULAR].join(" ")}>
    {children}
  </div>
);

const H1 = {
  BOLD: ({ style, className, children }) =>
    H1BOLD({ style, className, children }),
  SEMIBOLD: ({ style, className, children }) =>
    H1SEMIBOLD({ style, className, children }),
  REGULAR: ({ style, className, children }) =>
    H1REGULAR({ style, className, children }),
};

export default H1;
