import { removeUser, setBadPromoCode, uiClear } from "../../../Store/Actions";
import { setPayments } from "../../../Store/Actions/payments";
import {
  finishPaymentSaving,
  setGoodPromoCode,
  stopPaymentSaving,
} from "../../../Store/Actions/ui";
import wawaAPI, * as API_ROUTES from "../api";

export const submitUbiiPayment = (data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.post(API_ROUTES.UBII_PAYMENT, data, {
        headers,
      });
      if (response.data.success) {
        dispatch(finishPaymentSaving());
        dispatch(stopPaymentSaving());
        dispatch(fetchPayments());
      } else {
        dispatch(stopPaymentSaving());
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
      dispatch(stopPaymentSaving());
    }
  };
};

export let submitPaypalPayment = (data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.post(API_ROUTES.PAYPAL_PAYMENT, data, {
        headers,
      });
      if (response.data.success) {
        dispatch(finishPaymentSaving());
        dispatch(stopPaymentSaving());
        dispatch(fetchPayments());
      } else {
        dispatch(stopPaymentSaving());
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
      dispatch(stopPaymentSaving());
    }
  };
};

export const fetchPayments = (userId) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.get(API_ROUTES.PAYMENTS, {
        headers,
        params: {
          user: auth.user._id,
          status: "Completed",
        },
      });
      if (response.status === 200) {
        if (response.data.length) {
          dispatch(setPayments(response.data.reverse()));
        }
      }
      if (response.status === 401) {
        dispatch(removeUser());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchPromoCode = (data) => {
  return async (dispatch, getState) => {
    try {
      const { auth } = getState();
      const headers = {
        Authorization: "Bearer " + auth.user.token,
        authtype: "web",
      };
      let response = await wawaAPI.post(API_ROUTES.APPLY_PROMO, data, {
        headers,
      });
      if (response.status == 200) {
        console.log(response);
        dispatch(setGoodPromoCode());
      }
      if (response.status == 401) {
        dispatch(removeUser());
      } else if (response.status !== 200) {
        dispatch(setBadPromoCode());
      }
      setTimeout(() => {
        dispatch(uiClear());
      }, 2000);
    } catch (error) {
      console.log(error.request);
      dispatch(setBadPromoCode());
    }
  };
};
