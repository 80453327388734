import React from "react";
import styles from "./styles.module.css";

const H4BOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H4BOLD].join(" ")}>
    {children}
  </div>
);
const H4SEMIBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H4SEMIBOLD].join(" ")}>
    {children}
  </div>
);
const H4REGULAR = ({ style, className, children }) => (
  <div style={style} className={[className, styles.H4REGULAR].join(" ")}>
    {children}
  </div>
);

const H4 = {
  BOLD: ({ style, className, children }) =>
    H4BOLD({ style, className, children }),
  SEMIBOLD: ({ style, className, children }) =>
    H4SEMIBOLD({ style, className, children }),
  REGULAR: ({ style, className, children }) =>
    H4REGULAR({ style, className, children }),
};

export default H4;
