import {
  fetchActivePasses,
  fetchAssignPass,
  fetchCompanyEmployees,
  fetchCompanyPayPalPayment,
  fetchCompanyRequests,
  fetchCompanyUbiiPayment,
  fetchDeletePass,
  fetchManageRequest,
  fetchResetPassword,
  fetchUploadContacts,
  fetchValidatePassword,
  fetchCreateUser,
  fetchDeleteUser,
  fetchCompanyMovements,
} from "../../Config/Services/Admin";
import { fetchSignInCompany } from "../../Config/Services/Auth";
import {
  CREATE_COMPANY_USER,
  GET_COMPANY_REQUESTS,
  SET_COMPANY_REQUESTS,
  FILTER_REQUESTS,
  PASSWORD_VALIDATED,
  REMOVE_PLANS_FOR_USER,
  SET_COMPANY_EMPLOYEES,
  SET_PLANS_FOR_USER,
  SET_PLANS_FOR_USER_ASSGINED,
  SET_COMPANY_MOVEMENTS,
} from "../ActionTypes";

export const getCompanyRequests = (data) => {
  return (dispatch) => {
    dispatch(fetchCompanyRequests());
  };
};

export const createUserCompany = (data) => {
  return (dispatch) => {
    // dispatch(fetchPlans());
  };
};

export const uploadCompanyUsers = (data) => {
  return (dispatch) => {
    dispatch(fetchUploadContacts(data));
  };
};

export const confirmRequest = (data) => {
  return (dispatch) => {
    dispatch(fetchManageRequest(data));
  };
};

export const setCompanyEmployees = (data) => {
  return {
    type: SET_COMPANY_EMPLOYEES,
    data: data,
  };
};

export const getCompanyEmployees = (data) => {
  return (dispatch) => {
    dispatch(fetchCompanyEmployees(data));
  };
};

export const getCompanyMovements = (data) => {
  return (dispatch) => {
    dispatch(fetchCompanyMovements(data));
  };
};

export const setCompanyMovements = (data) => {
  return {
    type: SET_COMPANY_MOVEMENTS,
    data: data,
  };
};

export const setCompanyRequests = (data) => {
  return {
    type: SET_COMPANY_REQUESTS,
    data: data,
  };
};

export const filterRequest = (data) => {
  return {
    type: FILTER_REQUESTS,
    id: data,
  };
};

export const signInCompany = (data) => {
  return (dispatch) => {
    dispatch(fetchSignInCompany(data));
  };
};

export const validatePassword = (data) => {
  return (dispatch) => {
    dispatch(fetchValidatePassword(data));
  };
};

export const resetPassword = (data) => {
  return (dispatch) => {
    dispatch(fetchResetPassword(data));
  };
};

export const setResetPassword = () => {
  return {
    type: PASSWORD_VALIDATED,
  };
};

export const sendUbiiPayment = (data) => {
  return (dispatch) => {
    dispatch(fetchCompanyUbiiPayment(data));
  };
};

export const sendPayPalPayment = (data) => {
  return (dispatch) => {
    dispatch(fetchCompanyPayPalPayment(data));
  };
};

export const getActivePassesUser = (data, callback) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchActivePasses(data)).then((response) => {
        resolve(response);
      });
    });
  };
};

export const setPlansForUser = (id, data) => {
  return {
    type: SET_PLANS_FOR_USER,
    id: id,
    data: data,
  };
};

export const setAssignPass = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchAssignPass(id, data)).then((response) => {
        resolve(response);
      });
    });
  };
};

export const setDeletePass = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchDeletePass(id, data)).then((response) => {
        resolve(response);
      });
    });
  };
};

export const setPlansForUserAssigned = (id, data) => {
  return {
    type: SET_PLANS_FOR_USER_ASSGINED,
    id: id,
    data: data,
  };
};

export const setRemovePlansForUSer = (id, data) => {
  return {
    type: REMOVE_PLANS_FOR_USER,
    id: id,
    data: data,
  };
};

export const createUser = (data) => {
  return (dispatch) => {
    dispatch(fetchCreateUser(data));
  };
};

export const deleteUser = (data) => {
  return (dispatch) => {
    dispatch(fetchDeleteUser(data));
  };
};
// export const getPlans = () => {
//   return (dispatch) => {
//     dispatch(fetchPlans());
//   };
// };
