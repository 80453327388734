import {
  CHANGE_SIDEBAR,
  DISABLE_CODE_INPUT,
  ENABLE_CODE_INPUT,
  FINISH_PAYMENT_SAVING,
  HIDE_PROMO_MODAL,
  REMOVE_CODE_ERROR,
  REMOVE_PHONE_ERROR,
  SET_BAD_PROMO_CODE,
  SET_CODE_ERROR,
  SET_GOOD_PROMO_CODE,
  SET_PHONE_ERROR,
  SHOW_PROMO_MODAL,
  START_AUTH_LOADING,
  START_FETCHING_PROMO,
  START_PAYMENT_SAVING,
  STOP_AUTH_LOADING,
  STOP_PAYMENT_SAVING,
  UI_CLEAR,
  SHOW_ADD_USER_MODAL,
  HIDE_ADD_USER_MODAL,
} from "../ActionTypes";

const initialState = {
  authLoading: false,
  codeInputLogin: false,
  paymentSaving: false,
  paymentSaved: false,
  phoneError: false,
  codeError: false,
  isCollapsed: false,
  employeesModal: false,
  promoModal: false,
  fetchingPromo: false,
  promoValid: false,
  finishFetchingPromo: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_AUTH_LOADING:
      return {
        ...state,
        authLoading: true,
      };
    case STOP_AUTH_LOADING:
      return {
        ...state,
        authLoading: false,
      };
    case ENABLE_CODE_INPUT:
      return {
        ...state,
        codeInputLogin: true,
      };
    case DISABLE_CODE_INPUT:
      return {
        ...state,
        codeInputLogin: false,
      };
    case START_PAYMENT_SAVING:
      return {
        ...state,
        paymentSaving: true,
      };
    case STOP_PAYMENT_SAVING:
      return {
        ...state,
        paymentSaving: false,
      };
    case FINISH_PAYMENT_SAVING:
      return {
        ...state,
        paymentSaved: true,
      };
    case UI_CLEAR:
      return {
        authLoading: false,
        codeInputLogin: false,
        paymentSaving: false,
        paymentSaved: false,
        phoneError: false,
        codeError: false,
      };
    case SET_PHONE_ERROR:
      return {
        ...state,
        phoneError: true,
      };
    case REMOVE_PHONE_ERROR:
      return {
        ...state,
        phoneError: false,
      };
    case SET_CODE_ERROR:
      return {
        ...state,
        codeError: true,
      };
    case REMOVE_CODE_ERROR:
      return {
        ...state,
        codeError: false,
      };
    case CHANGE_SIDEBAR:
      return {
        ...state,
        isCollapsed: !state.isCollapsed,
      };
    case SHOW_ADD_USER_MODAL:
      return {
        ...state,
        employeesModal: true,
      };
    case HIDE_ADD_USER_MODAL:
      return {
        ...state,
        employeesModal: false,
      };
    case SHOW_PROMO_MODAL:
      return {
        ...state,
        promoModal: true,
      };
    case HIDE_PROMO_MODAL:
      return {
        ...state,
        promoModal: false,
      };
    case START_FETCHING_PROMO:
      return {
        ...state,
        fetchingPromo: true,
        finishFetchingPromo: false,
      };
    case SET_GOOD_PROMO_CODE:
      return {
        ...state,
        promoValid: true,
        fetchingPromo: false,
        finishFetchingPromo: true,
      };
    case SET_BAD_PROMO_CODE:
      return {
        ...state,
        promoValid: false,
        fetchingPromo: false,
        finishFetchingPromo: true,
      };
    default:
      return state;
  }
};
export default reducer;
