import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Modal from "react-bootstrap/Modal";
import { IoCheckmarkSharp, IoTrashOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../Components/Button";
import H4 from "../../../Components/Text/H4";
import PTITLE from "../../../Components/Text/PTitle";
import AppLayout from "../../../Containers/AppLayout";
import { confirmRequest, getCompanyRequests } from "../../../Store/Actions";
import styles from "./styles.module.css";

// code for CARET`
// headerStyle: { display: "flex", alignItems: "center" },
// sortCaret: (order, column) => {
//   if (!order)
//     return (
//       <span style={{ display: "flex", flexDirection: "row" }}>
//         <div className={styles.upArrow}></div>
//         <div className={styles.downArrow}></div>
//       </span>
//     );
//   else if (order === "asc")
//     return (
//       <span style={{ display: "flex", flexDirection: "row" }}>
//         <div className={styles.upArrow}></div>
//         <div className={styles.downArrow}></div>
//       </span>
//     );
//   else if (order === "desc")
//     return (
//       <span style={{ display: "flex", flexDirection: "row" }}>
//         <div className={styles.upArrow}></div>
//         <div className={styles.downArrow}></div>
//       </span>
//     );
//   return null;
// },

const AdminUsers = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const requests = useSelector((state) => state.admin.companyRequests);
  const [modalInfo, setModalInfo] = useState({});
  const [modalType, setModalType] = useState("reject");
  const [products, setProducts] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (requests?.length) {
      const auxProducts = requests.map((item, i) => {
        let product = {
          name: `${item.user?.firstName} ${item.user?.lastName}`,
          id: item.user.documentID,
          phone: item.user?.phone,
          email: item.user?.email,
          actions: item,
        };
        return product;
      });
      setProducts(auxProducts);
    }
  }, [requests]);

  useEffect(() => {
    dispatch(getCompanyRequests());
  }, []);

  const columns = [
    {
      dataField: "name",
      text: "Nombre",
      sort: true,
      style: {
        border: "none",
      },
      type: "date",
    },
    {
      dataField: "id",
      text: "Cédula",
      sort: true,
      style: {
        border: "none",
      },
      type: "string",
    },
    {
      dataField: "phone",
      text: "Teléfono",
      sort: true,
      style: {
        border: "none",
      },
      // sortFunc: (a, b, order, dataField, rowA, rowB) => {
      //   if (order === "asc") {
      //     return a.amount - b.amount;
      //   } else if (order === "desc") {
      //     return b.amount - a.amount;
      //   }
      // },
      type: "string",
    },
    {
      dataField: "email",
      text: "Correo",
      sort: true,
      style: {
        border: "none",
      },
      type: "string",
      // sortFunc: (a, b, order, dataField, rowA, rowB) => {
      //   if (order === "asc") {
      //     return a - b;
      //   } else if (order === "desc") {
      //     return b - a;
      //   }
      // },
    },
    {
      dataField: "actions",
      text: "",
      sort: true,
      style: {
        border: "none",
      },
      type: "string",
      formatter: (info) => {
        return (
          <div className={styles.actions}>
            <div
              onClick={() => {
                setModalInfo(info);
                setModalType("reject");
                setShow(true);
              }}
              className={styles.cancel}
            >
              <IoTrashOutline size={25} style={{ color: "#ec8686" }} />
            </div>
            <div
              onClick={() => {
                setModalInfo(info);
                setModalType("accept");
                setShow(true);
              }}
              className={styles.accept}
            >
              <IoCheckmarkSharp size={25} style={{ color: "#5ad951" }} />
            </div>
          </div>
        );
      },
    },
  ];

  const { SearchBar, ClearSearchButton } = Search;
  const options = {
    onSizePerPageChange: (sizePerPage, page) => {},
    onPageChange: (page, sizePerPage) => {},
  };

  return (
    <AppLayout sideBar>
      <div className={styles.homeWrapper}>
        <H4.BOLD style={{ marginTop: 35 }}>Lista de Peticiones</H4.BOLD>
        <ToolkitProvider keyField="id" data={products} columns={columns} search>
          {(props) => (
            <div className={styles.test}>
              <div className={styles.search}>
                <SearchBar
                  srText={null}
                  placeholder={"Buscar"}
                  {...props.searchProps}
                />
              </div>
              <BootstrapTable
                {...props.baseProps}
                keyField={"id"}
                hover
                sort={{
                  order: "desc",
                  dataField: "date",
                }}
                pagination={paginationFactory(options)}
                rowStyle={{ border: "none" }}
                wrapperClasses="table-responsive"
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <Modal
        show={show}
        fullscreen={"sm-down"}
        centered
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Solicitud de {modalInfo?.user?.firstName}{" "}
            {modalInfo?.user?.lastName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PTITLE.REGULAR>
            ¿Estás seguro que deseas{" "}
            <span style={{ fontWeight: "bold" }}>
              {modalType === "reject" ? "rechazar" : "aceptar"}
            </span>{" "}
            la solicitud de este usuario?
          </PTITLE.REGULAR>
          <div className={styles.buttonsModal}>
            <div onClick={() => setShow(false)} className={styles.cancelButton}>
              <PTITLE.BOLD>Cancelar</PTITLE.BOLD>
            </div>
            <div className={styles.confirmButton}>
              <Button
                onPress={() => {
                  dispatch(
                    confirmRequest({
                      status: modalType === "reject" ? "rejected" : "accepted",
                      requestID: modalInfo?._id,
                    })
                  );
                }}
                style={{ height: 40 }}
                title={"Confirmar"}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </AppLayout>
  );
};

export default React.memo(AdminUsers);
