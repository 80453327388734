import React from "react";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import { useHistory } from "react-router-dom";
import images from "../../../Assets/Images";
import Button from "../../../Components/Button";
import H4 from "../../../Components/Text/H4";
import Text from "../../../Components/Text/Title";
import AppLayout from "../../../Containers/AppLayout";

const Page404 = (props) => {
  const history = useHistory();

  return (
    <AppLayout overflow={false} fluid navBar={false} circleBackground>
      <Col
        // style={{ height: "100vh" }}
        className={"justify-content-center align-items-center"}
      >
        <Row className={"justify-content-center align-items-center"}>
          <Image style={{ width: 250 }} src={images.logoPurple} />
        </Row>
        <Row className={"justify-content-center align-items-center"}>
          <Text.BOLD
            style={{
              fontSize: "25vh",
              alignSelf: "center",
              textAlign: "center",
              fontWeight: "bold",
              color: "#8000E6",
            }}
          >
            404
          </Text.BOLD>
        </Row>
        <Row className={"justify-content-center align-items-center"}>
          <H4.SEMIBOLD
            style={{
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            La página no fue encontrada
          </H4.SEMIBOLD>
        </Row>
        <Row className={"justify-content-center align-items-center"}>
          <Button
            style={{ maxWidth: 200, marginTop: 25 }}
            title={"Regresar al inicio"}
            onPress={() => history.replace("/")}
          />
        </Row>
      </Col>
    </AppLayout>
  );
};

export default Page404;
