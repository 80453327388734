import React, { useEffect, useRef, useState } from "react";
import ReactCodeInput from "react-code-input";
import "react-codes-input/lib/react-codes-input.min.css";
import { useDispatch } from "react-redux";
import "./code.css";
import styles from "./styles.module.css";

const CodeInput = ({ onComplete, onIncomplete, removeCodeError }) => {
  const dispatch = useDispatch();
  const myRef = useRef(null);

  const [pin, setPin] = useState("");

  useEffect(() => {
    if (pin.length === 4) {
      onComplete(pin);
    } else {
      onIncomplete();
    }
    // console.log(pin);
  }, [pin]);

  return (
    <>
      {/*      <ReactCodesInput
        initialFocus={true}
        // wrapperRef={$pinWrapperRef}
        id="pin"
        codeLength={4}
        type="number" // ['alphanumeric', 'alpha', 'number']
        hide={false}
        placeholder=""
        value={pin}
        onChange={(res) => {
          setPin(res);
          removeCodeError();
        }}
        focusColor={colors.purpleLight}
        classNameCodeWrapper={styles.codeInput}
        // classNameWrapper={styles.wrapper}
        classNameComponent={styles.component}
        classNameCode={styles.test}
        classNameCodeWrapperFocus={styles.codeFocus}
        customStyleWrapper={{
          borderWidth: 0,
        }}
        // customStyleCode={{
        //   borderWitdh: 0
        // }}
      /> */}
      <ReactCodeInput
        autoFocus={true}
        // wrapperRef={$pinWrapperRef}
        id="pin"
        fields={4}
        type="number"
        placeholder=""
        value={pin}
        onChange={(res) => {
          setPin(res);
          removeCodeError();
          // console.log(res);
        }}
        inputStyle={{
          borderRadius: "10px",
          border: "1px solid #bbbbbb",
          marginLeft: "10px",
          marginRight: "10px",
          height: "50px",
          width: "50px",
          textAlign: "center",
          marginRight: 15,
          marginLeft: 15,
        }}
        className={styles.component}
        // style={{
        //   border: 0 + "px solid transparent",
        //   fontFamily: "Montserrat",
        //   display: "flex",
        //   // backgroundColor: "red",
        //   // flexDirection: "row",
        //   justifyContent: "space-between",
        // }}
      />
    </>
  );
};

export default CodeInput;
