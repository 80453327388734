import React from "react";
import styles from "./styles.module.css";

const PBODYBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.PBODYBOLD].join(" ")}>
    {children}
  </div>
);
const PBODYSEMIBOLD = ({ style, className, children }) => (
  <div style={style} className={[className, styles.PBODYSEMIBOLD].join(" ")}>
    {children}
  </div>
);
const PBODYREGULAR = ({ style, className, children }) => (
  <div style={style} className={[className, styles.PBODYREGULAR].join(" ")}>
    {children}
  </div>
);

const PBODY = {
  BOLD: ({ style, className, children }) =>
    PBODYBOLD({ style, className, children }),
  SEMIBOLD: ({ style, className, children }) =>
    PBODYSEMIBOLD({ style, className, children }),
  REGULAR: ({ style, className, children }) =>
    PBODYREGULAR({ style, className, children }),
};

export default PBODY;
