import React from "react";
import {
  IoIosArrowBack,
  IoIosCard,
  IoIosNotifications,
  IoIosSettings,
  IoMdPeople,
  IoIosList,
} from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { changeSideBar } from "../../Store/Actions";
import SideBarItem from "../SideBarItem";
import styles from "./styles.module.css";

const SideBar = (props) => {
  const dispatch = useDispatch();
  const isCollapsed = useSelector((state) => state.ui.isCollapsed);
  const user = useSelector((state) => state.auth.user);

  return (
    <div className={isCollapsed ? styles.sideBar2 : styles.sideBar}>
      {/* <div className={styles.header}>
        {user.firstName} {user.lastName}
      </div> */}
      <div className={styles.links}>
        <SideBarItem
          isCollapsed={isCollapsed}
          link={"/"}
          title={"Usuarios"}
          icon={<IoMdPeople size={28} />}
        />
        <SideBarItem
          isCollapsed={isCollapsed}
          link={"/peticiones"}
          title={"Peticiones"}
          icon={<IoIosNotifications size={28} />}
        />
        <SideBarItem
          isCollapsed={isCollapsed}
          link={"/agregar-saldo"}
          title={"Agregar saldo"}
          icon={<IoIosCard size={25} />}
        />
        <SideBarItem
          isCollapsed={isCollapsed}
          link={"/movimientos"}
          title={"Movimientos"}
          icon={<IoIosList size={25} />}
        />
        <SideBarItem
          isCollapsed={isCollapsed}
          link={"/ajustes"}
          title={"Ajustes"}
          icon={<IoIosSettings size={30} />}
        />
      </div>
      <div className={styles.footer}>
        {isCollapsed && (
          <>
            <span style={{ color: "#8b37ff" }}>♥</span> <br />
            La Wawa
          </>
        )}
        {!isCollapsed && (
          <div className={styles.collapsedFooter}>
            Hecho con <span style={{ color: "#8b37ff" }}>♥</span> <br />
            La Wawa - Venezuela 2021
          </div>
        )}
      </div>
      <div
        onClick={() => dispatch(changeSideBar())}
        className={isCollapsed ? styles.icon2 : styles.icon}
      >
        <IoIosArrowBack
          className={isCollapsed ? styles.iconStyle : styles.iconStyle2}
          size={30}
        />
      </div>
    </div>
  );
};

export default SideBar;
