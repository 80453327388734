import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../Components/Button";
import AppLayout from "../../../Containers/AppLayout";
import { resetPassword, validatePassword } from "../../../Store/Actions";
import styles from "./styles.module.css";
import H6 from "../../../Components/Text/H6";

const Settings = (props) => {
  const dispatch = useDispatch();

  const passwordValidated = useSelector(
    (state) => state.admin.passwordValidated
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors: errors1 },
    control,
    getValues,
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    watch: watch2,
    control: control2,
    getValues: getValues2,
  } = useForm();

  const onSubmit = (data, e) => {
    dispatch(validatePassword(data));
  };

  const onSubmit2 = (data, e) => {
    console.log(data);
    if (data.password !== data.password2) {
      alert("Contraseñas no coinciden");
      return;
    } else if (data.password === data.password2) {
      dispatch(resetPassword(data));
    }
  };

  const submitErrorHandler = (errors, e) => console.log(errors);

  return (
    <AppLayout sideBar>
      <div className={styles.container}>
        <div className={styles.settings}>
          {!passwordValidated && (
            <form onSubmit={handleSubmit(onSubmit, submitErrorHandler)}>
              <H6.REGULAR>Cambio de contraseña</H6.REGULAR>
              <input
                className={styles.input}
                type="password"
                autoComplete="off"
                autoCorrect="off"
                {...register("password", {
                  required: true,
                })}
                required
                placeholder="Contraseña actual"
              />
              <span
                className={
                  errors1.password ? styles.errors : styles.errorsHidden
                }
              >
                Este campo es requerido
              </span>
              <Button
                variant={"primary"}
                title={"Validar contraseña"}
                // loading={authLoading}
                form
                style={{
                  width: "100%",
                  padding: 10,
                  marginTop: 20,
                  marginBottom: 10,
                  border: "none",
                }}
              />
            </form>
          )}
          {passwordValidated && (
            <form onSubmit={handleSubmit2(onSubmit2, submitErrorHandler)}>
              <H6.REGULAR>Cambio de contraseña</H6.REGULAR>
              <input
                className={styles.input}
                type="password"
                autoComplete="off"
                autoCorrect="off"
                {...register2("password", {
                  required: true,
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                })}
                required
                placeholder="Nueva contraseña"
              />
              <span
                className={
                  errors2.password ? styles.errors : styles.errorsHidden
                }
              >
                Este campo es requerido o las contraseñas no coinciden
              </span>
              <input
                className={styles.input}
                type="password"
                autoComplete="off"
                autoCorrect="off"
                {...register2("password2", {
                  required: true,
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                })}
                required
                placeholder="Repetir contraseña"
              />
              <span
                className={
                  errors2.password2 ? styles.errors : styles.errorsHidden
                }
              >
                Este campo es requerido o las contraseñas no coinciden
              </span>
              <Button
                variant={"primary"}
                title={"Cambiar contraseña"}
                // loading={authLoading}
                form
                style={{
                  width: "100%",
                  padding: 10,
                  marginTop: 20,
                  marginBottom: 10,
                  border: "none",
                }}
              />
            </form>
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default Settings;
