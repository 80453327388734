import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.css";

const navigationItems = (props) => {
  return (
    <div
      className={`${styles.NavigationItem} ${props.action && styles.action}`}
    >
      <NavLink
        to={props.link}
        exact={props.exact}
        activeClassName={styles.active}
      >
        {props.children}
      </NavLink>
    </div>
  );
};

export default navigationItems;
