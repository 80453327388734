import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../Button";
import H2 from "../Text/H2";
import H4 from "../Text/H4";
import H5 from "../Text/H5";
import PCAPTION from "../Text/Pcaption";
import styles from "./styles.module.css";

const AuthCard = ({
  title,
  step,
  subTitle1,
  subTitle2,
  message,
  children,
  buttonAction,
  buttonTitle,
  buttonDisabled,
  login,
  signup,
  questions = false,
  admin,
  isForm,
}) => {
  const authLoading = useSelector((state) => state.ui.authLoading);

  const buttonWrapper = {
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    alignItems: "flex-end",
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div style={{ display: "flex", flex: 1 }}>
          <H2.BOLD style={{ color: "white" }}>{title}</H2.BOLD>
        </div>
        {step ? (
          <H5.SEMIBOLD style={{ color: "white" }}>{step}</H5.SEMIBOLD>
        ) : null}
      </div>
      <div className={styles.body}>
        <H4.BOLD className={styles.lineHeight}>{subTitle1}</H4.BOLD>
        {subTitle2 ? (
          <H4.BOLD className={styles.lineHeight}>{subTitle2}</H4.BOLD>
        ) : null}
        <div>
          <PCAPTION.SEMIBOLD
            style={{ color: "#858585", marginTop: 13, marginBottom: 25 }}
          >
            {message}
          </PCAPTION.SEMIBOLD>
        </div>
        {children}
        {!isForm && (
          <div style={buttonWrapper}>
            <Button
              onPress={buttonAction}
              style={{
                width: "100%",
                padding: 10,
                marginTop: 20,
                marginBottom: 10,
              }}
              variant={"primary"}
              title={buttonTitle}
              disabled={buttonDisabled}
              loading={authLoading}
            />
          </div>
        )}
        {questions && admin && (
          <>
            <div className={styles.question}>
              ¿Quieres registrar a tu empresa?{" "}
              <Link className={styles.register} to="/registro-de-empresa">
                <span>Regístrate</span>
              </Link>
            </div>
            <div className={styles.question}>
              <Link className={styles.register} to="/">
                <span>Ingresa como usuario</span>
              </Link>
            </div>
          </>
        )}

        {questions && login && (
          <>
            <div className={styles.question}>
              ¿No tienes una cuenta?{" "}
              <Link className={styles.register} to="/registro">
                <span>Regístrate</span>
              </Link>
            </div>
            <div className={styles.question}>
              <Link className={styles.register} to="/empresas">
                <span>Ingresa como empresa</span>
              </Link>
            </div>
          </>
        )}

        {/* {signup && process.env.NODE_ENV === "development" && (
          <Link
            style={{ textAlign: "center", fontSize: "0.8rem", marginTop: 10 }}
            to="/inicio"
          >
            ¿Ya tienes una cuenta? Inicia sesión
          </Link>
        )} */}
      </div>
    </div>
  );
};

export default AuthCard;
